import $ from 'jquery';
import React from 'react';
import { findDOMNode } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import Pages from './pages';
import Dashboard from './user_pages/dashboard'
import DashboardNew from './user_pages/dashboard_new';
import CreateCompany from './user_pages/create_company';
import FileStore from './user_pages/file_store';
import QuestMaster from './user_pages/question_master';

import TutorialPlay from './user_pages/play_video';
import StartQuiz from './user_pages/start_quiz';
import QuizReportTmp from './user_pages/quiz_report_tmp';
import QuizReport from './user_pages/quiz_report';
//Reporting Modules
import RptPerfoReports from './user_pages/rpt_perfo_report';
import RptAnalyticReports from './user_pages/emp_analytics_avg';

import Tree_Members from './user_pages/tree_members';
import Post_Timeline from './user_pages/post_timeline';
import Post_Timeline_New from './user_pages/post_timeline_new';
import Calender_Pg from './user_pages/calender_pg';
import Call_List from './user_pages/call_list';
import G_Cloud from './user_pages/g_cloud';
class App extends React.Component{
  componentDidMount() {
    // const script = document.createElement("script");    
    // script.async = true;    
    // script.src = "./assets/js/default/active.js"; 
    // document.body.appendChild(script);   
      }
  render(){
   
    return <Router>
      <Route exact path="/" component={Pages} />
      <Route exact path="/Dashboard" component={DashboardNew}/>
      <Route exact path="/createcompany" component={CreateCompany} />
      <Route exact path="/filestore" component={FileStore} />
     
      <Route exact path="/quizReport" component={QuizReport} />
      <Route exact path="/perfomaceReport" component={RptPerfoReports}/>
      <Route exact path="/empAnalyticsReport" component={RptAnalyticReports} />

      <Route exact path="/treeMembers" component={Tree_Members}/>
      <Route exact path="/post" component={Post_Timeline_New}/>
      <Route exact path="/calender_pg" component={Calender_Pg} />
      <Route exact path="/call_list" component={Call_List} />
      <Route exact path='/gcloud' component={G_Cloud}/>
    </Router>
  }
  
}
export default App;