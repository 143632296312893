import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,getSearchOutPut} from '../function_lib/common_lib';
// import listReactFiles from 'list-react-files'
import Header from './header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/my_logo.jpg';
import defaultPic from '../assets/images/9.png';
//Component
import Post_Thumb from '../components/post_thumb';
import Add_New_Post from '../components/add_new_post';
import Socket_Lib from '../function_lib/socket_list';



let loginUser="";
let userObject="";
let clsObject;

let socketIO;
class DashboardNew extends React.Component{

  /* Method to Handle AddNewPost Edit */
  EditAddPost(data_s){
    //alert(JSON.stringify(data_s));
    clsObject.setState(state=>({stPostEdit:data_s}));
    clsObject.forceUpdateHandler();
  }
  CloseExistingPost(clsModal){
      clsObject.closeMe(clsModal);
      clsObject.setState(state=>({stPostEdit:""}));
      clsObject.forceUpdateHandler();
  }
  closeMe(clName) {
    $(clName).hide();
  }
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.state={ stRecentPost:"",isList:false,stPostEdit:""};
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.objSocket=new Socket_Lib();
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
      
      getPostData(refId=0,dirParentId=0){
        //alert("i m in post data");
        var map=[];
        map.push({name:"curl_type",value:"getPostData"});
        map.push({name:"res_func",value:"callBackNg"});
        //alert("testing== "+dirParentId);
          map.push({name:"parent_id",value:dirParentId});
          map["res_func"]="callBackNg";
          map["curl_type"]="getPostData";
          CallMapMethod(clsObject,map);
        }
        
callBackNg(data,msg){
    //alert(msg);
    if(data.trim()!=="" && data.trim()!=="0"){
        var listItems=JSON.parse(data);
        this.setState(state=>({stRecentPost:JSON.parse(data),isList:true}));
        //clsObject.setState({stRecentPost:listItems,isList:true});
        //alert(JSON.stringify(this.state.stListItems));
        this.forceUpdateHandler();
        RespBkMthdMapp(clsObject,"select_target","onChangeDirSearch","");
       
     }
}
onChangeDirSearch(parentId){
//alert(parentId);
 if($("#recent_filter").val()!==parentId){
  $("#recent_filter").val(parentId);
  this.getPostData(0,parentId);

 }
 
}
      result(vart){
          //alert(vart);
      }
      componentDidMount(){
        this.objSocket.isRun=true;
         clsObject=this;
         this.getPostData();
         $("#serParentId").change(function(){
          // alert("i am in testing");
          var getCallFunc= $(this).attr("resp_dest");
          var refId=$(this).val();
          clsObject.getPostData();
          //alert("ai on change "+getCallFunc+","+refId);
          //return obCls[getCallFunc](refId);
         });
         RespBkMthdMapp(clsObject,"_setSearchKey","keySearch");
          //listReactFiles("pub_dir/").then(files => console.log(files))
      }
      keySearch(data){
        getSearchOutPut("desk_thumb","child_desk_thumb",data);
      }
    render(){
        
        const adminOption=()=>{
         
        }
        return(<div>
            <Header/>
            
            <input type="hidden" name="recent_filter" id="recent_filter" />
            {/* <Side_Bar/> */}
   <div className="content-wrapper">
   <section className="content-header">
  <div className="container-fluid">
    <div className="row mb-2" id="new_catmap">
      <div className="col-lg-3 col-md-2 col-sm-3">
        <h1>Timeline</h1>
      </div>
      <div className="col-lg-3 col-md-2 col-sm-3">
  <input className="form-control _setSearchKey" type="text" placeholder="Search" aria-label="Search" />
      </div>
      <div className="col-lg-3 col-md-2 col-sm-3">
     
      <select id="serParentId" name="serParentId" resp_dest="getTestCB" className="custom-select">
        <option value="0">Select Directory</option>
      </select>
      </div>
    </div>
  </div>
</section>
            <section className="content">
                <div className="container-fluid">
                { this.state.isList ?<Post_Thumb  callBackMethod={this.EditAddPost} stRecentPost={this.state.stRecentPost}/>:''} 
                </div>
            </section>
        </div>
        <aside className="control-sidebar control-sidebar-dark">
          
          <div className="p-3">
            <h5>Title</h5>
            <p>Sidebar content</p>
          </div>
        </aside>
        <Footer/>
        <Add_New_Post callBackClose={this.CloseExistingPost} stEditData={this.state.stPostEdit}/>
      </div>)
    }
}
export default DashboardNew