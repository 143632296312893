import $,{ extend } from 'jquery';
import React from 'react';
class Add_Reminder extends React.Component{
    closeMe(clName) {
        $(clName).hide();
      }
    render(){
        return(<div className="modal fade" id="modal_add_reminder">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3 className="card-title">Bank Details</h3>
                        <a className="close" onClick={()=>this.closeMe("#modal_add_reminder")} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </a>
                    </div>
                   
                    <form id="frmBank" role="form">
                        <input type="hidden" name="bank_emp_id" id="bank_emp_id" value="" />
                        <input type="hidden" name="bank_comp_id" id="bank_comp_id" value="" />
                        <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                        <input type="hidden" name="curl_type" id="curl_type" value="insBankDetails" />
                        <input type="hidden" name="m_type" id="m_type" value="insBankDetails" />
                        <input type="hidden" name="type_id" id="type_id" value="21" />
                        <div className="card-body">
                            <div className="form-group">
                                <label for="exampleInputEmail1">Bank Name</label>
                                <input type="text" className="form-control" id="col1" name="col1" placeholder="Bank Name" />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputPassword1">IFSC Code</label>
                                <input type="text" className="form-control" id="col2" name="col2" placeholder="IFSC Code" />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputPassword1">Branch Details</label>
                                <input type="text" className="form-control" id="col3" name="col3" placeholder="Branch Details" />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputPassword1">A/c No.</label>
                                <input type="text" className="form-control" id="col4" name="col4" placeholder="Contact No." />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputPassword1">Confirm A/c No.</label>
                                <input type="text" className="form-control" id="col5" name="col5" placeholder="Email ID" />
                            </div>
                            <div className="form-group">
                                <label for="exampleInputFile">Upload Bank Cheque/ Statement</label>
                                <div className="input-group">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="exampleInputFile" />
                                        <label className="custom-file-label" for="exampleInputFile">Choose file</label>
                                    </div>
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="">Upload</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
    
                        <div className="card-footer">
                            <button type="button" className="btn _btnSetBank btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
    
    
            </div>
           
        </div>
        
    </div>)
    }
}
export default Add_Reminder;