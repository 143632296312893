import $ from 'jquery';
//import 'jquery-ui';
import React,{ useState } from 'react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import DatePicker from "react-datepicker";
import { Accordion,Card,Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {CanvasJSReact} from '../assets/canvasjs.react';
import CorrImg from '../assets/images/doc-green-sign.jpg';
import WrgImg from '../assets/images/doc-red-sign.jpg';
import OrgChart from '@balkangraph/orgchart.js';
// import OrgChart from './familytree';

//import OrgChart from '../assets/js/orgchart';
//import OrgChart from 'react-balkangraph-orgchart';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
var XMLParser = require('react-xml-parser');
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

window.jQuery = $;
window.$ = $;
global.jQuery = $;
//const appUrl="http://localhost:65287/Service.svc/";
// const appUrl="http://localhost/mydream/";

const appUrl="https://mydreamdata.ashtechnologies.in/";
//const appUrl="http://localhost:8081/";
//const appUrl="http://192.168.0.107:8081/";


//const appUrl="http://202.66.177.213:8081/";
// const appUrl="https://cona.chhabracreativebrains.com/Service.svc/";

export const drawChart=(obCls,chartData,divId)=>{
  OrgChart.templates.ana.plus ='<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
  + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>';

OrgChart.templates.invisibleGroup.padding = [20, 0, 0, 0];

var chart = new OrgChart(document.getElementById(divId), {
  template: "ana",
  enableDragDrop: true,
  assistantSeparation: 170,
  menu: {
      pdfPreview: {
          text: "Export to PDF",
          icon: OrgChart.icon.pdf(24, 24, '#7A7A7A'),
          onClick: preview
      },
      csv: { text: "Save as CSV" }
  },
  nodeMenu: {
      details: { text: "Details" },
      edit: { text: "Edit" },
      add: { text: "Add",onClick: addAssistant },
      remove: { text: "Remove" }
  },
  align: OrgChart.ORIENTATION,
  toolbar: {
      fullScreen: true,
      zoom: true,
      fit: true,
      expandAll: true
  },
  nodeBinding: {
      field_0: "name",
      field_1: "title",
      img_0: "img"
  },
  tags: {
      "top-management": {
          template: "invisibleGroup",
          subTreeConfig: {
              orientation: OrgChart.orientation.bottom,
              collapse: {
                  level: 1
              }
          }
      },
      "it-team": {
          subTreeConfig: {
              layout: OrgChart.mixed,
              collapse: {
                  level: 1
              }
          },
      },
      "hr-team": {
          subTreeConfig: {
              layout: OrgChart.treeRightOffset,
              collapse: {
                  level: 1
              }
          },
      },
      "sales-team": {
          subTreeConfig: {
              layout: OrgChart.treeLeftOffset,
              collapse: {
                  level: 1
              }
          },
      },
      "seo-menu": {
          nodeMenu: {
              addAssistant: { text: "Add New Member", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: addAssistant },
              edit: { text: "Edit" },
              details: { text: "Details" },
          }
      },
      "menu-without-add": {
          nodeMenu: {
              details: { text: "Details" },
              edit: { text: "Edit" },
              remove: { text: "Remove" }
          }
      },
      "department": {
          template: "group",
          nodeMenu: {
              addManager: { text: "Add new manager", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: addManager },
              remove: { text: "Remove department" },
              edit: { text: "Edit department" },
              nodePdfPreview: { text: "Export department to PDF", icon: OrgChart.icon.pdf(24, 24, "#7A7A7A"), onClick: nodePdfPreview }
          }
      }
  }
});

chart.on("added", function (sender, id) {
  sender.editUI.show(id);
});

chart.on('drop', function (sender, draggedNodeId, droppedNodeId) {
  var draggedNode = sender.getNode(draggedNodeId);
  var droppedNode = sender.getNode(droppedNodeId);

  if (droppedNode.tags.indexOf("department") != -1 && draggedNode.tags.indexOf("department") == -1) {
      var draggedNodeData = sender.get(draggedNode.id);
      draggedNodeData.pid = null;
      draggedNodeData.stpid = droppedNode.id;
      sender.updateNode(draggedNodeData);
      return false;
  }
});

chart.editUI.on('field', function (sender, args) {
  var isDeprtment = sender.node.tags.indexOf("department") != -1;
  var deprtmentFileds = ["name"];

  if (isDeprtment && deprtmentFileds.indexOf(args.name) == -1) {
      return false;
  }
});

chart.on('exportstart', function (sender, args) {
  args.styles = document.getElementById('myStyles').outerHTML;
});

chart.load(chartData);

function preview() {
  OrgChart.pdfPrevUI.show(chart, {
      format: 'A4'
  });
}

function nodePdfPreview(nodeId) {
  OrgChart.pdfPrevUI.show(chart, {
      format: 'A4',
      nodeId: nodeId
  });
}

function addSharholder(nodeId) {
  chart.addNode({ id: OrgChart.randomId(), pid: nodeId, tags: ["menu-without-add"] });
}

function addAssistant(nodeId) {
  //alert(nodeId);
  /*
  var node = chart.getNode(nodeId);
  var data = { id: OrgChart.randomId(), pid: node.stParent.id, tags: ["assistant"] };
  */
  //alert("i am in add");
  obCls.showMe(nodeId);
  //chart.addNode(data);
}


function addDepartment(nodeId) {
  var node = chart.getNode(nodeId);
  var data = { id: OrgChart.randomId(), pid: node.stParent.id, tags: ["department"] };
  chart.addNode(data);
}

function addManager(nodeId) {
  chart.addNode({ id: OrgChart.randomId(), stpid: nodeId });
}
}


export const setProfilePic=(obj, bdata)=> {
  //alert(bdata);
  if(bdata!= null && bdata!=="0"){
    obj.src =  bdata;
  }
}


export const customAlert=(title,msg,urls,oks="0",yesno="0")=>{
   var buttons_=[];
   if(yesno!=="0"){
     buttons_= [
         {
           label: 'Yes',
           onClick: () => window.location.href=urls
         },
         {
           label: 'No',
           onClick: () => window.location.href=urls
         }
       ];
   }else if(oks!=="0"){
     if(urls!=="0"){
      buttons_=[
        {
          label: 'ok',
          onClick: () => window.location.href=urls
        }];
     }else{
      buttons_=[
        {
          label: 'ok',
        }];
     }
      
   }

   confirmAlert({
      title: title,
      message: msg,
      buttons: buttons_
    });
}

export function readURL(input) {
  //if()
  var attr = $(input).attr('ref_count');
  
  if(typeof attr!=='undefined' && attr !== false){
     $('.'+$(input).attr("ref_count")).text("Uploaded Count -"+input.files.length);
  }else{
     if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            $('.'+$(input).attr("ref_img")).attr('src', e.target.result);
        }
        reader.readAsDataURL(input.files[0]);
    }
  }
  
}
export const shareMultiOptions=async(obFiles,vTitle="Loan Documents",vDescr="")=>{
  //alert(obFiles[0]);
  
  if(navigator.share){
    try{
      let shareData;
      shareData = {
        title: vTitle,
        text: vDescr,
        files:obFiles,
     }
     await navigator.share(shareData);
    }catch(err){
      alert("Unable to process share documents ,kindly refresh and try again!");
      console.log('Error: ', err);
    }
  }
}
export const dataURLtoFile=(dataurl, filename)=>{
  //alert(dataurl);
  //dataurl=dataurl.replace(/^data:image\/[^;]+/, 'data:application/octet-stream');
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
     
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  //alert(filename+" "+mime);
  return new File([u8arr], filename, {type:mime});
}

export const getAttendanceList=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["month_nm"]+"</td><td>P:["+lists[i]["Present"]+"]</td><td>A:["+lists[i]["Absents"]+
      "]</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table class=\"table table-sm table-borderless\"></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
}

export const GetTripReports=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      var strToClose="";
      if(lists[i]["tip_status"]==="Open"){
            strToClose="<tr><td style=\"color:red\">Not Completed: </td><td>"+
            "<a href=\"/OnTripComp?ref="+lists[i]["id"]+"\">Click to Mark as Completed</a></td></tr>"
      }
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["entry_date"]+"</td><td>Status :"+lists[i]["tip_status"]+"</td>"+
      "</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table  class=\"table table-sm table-borderless\"><tr>"+"<td>Started From :</td>"+
          "<td>"+lists[i]["trip_start_from"]+"</td></tr><tr>"+
          "<td>Initial Reading : </td>"+"<td>"+lists[i]["init_km_read"]+"</td></tr><tr>"+
          "<td>Destination To :</td>"+"<td>"+lists[i]["trip_desti_to"]+"</td></tr><tr>"+
          "<td>Trip End Km :</td>"+"<td>"+lists[i]["comp_km_read"]+"</td></tr><tr>"+
          "<td>Status :</td>"+"<td>"+lists[i]["tip_status"]+"</td>"+strToClose+
          "</tr></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
}

export const GetLeaveRequest=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["col1"]+"</td><td>"+lists[i]["date"]+"</td><td>"+lists[i]["status"]+
      "</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table  class=\"table table-sm table-borderless\"><tr><td colspan=\"3\">"+ lists[i]["details"]+"</td></tr>"+
          "<tr><td>From</td><td>To</td><td>Status</td></tr>"+
          "<tr>"+"<td>"+lists[i]["c_req_from"]+"</td>"+
          "<td>"+lists[i]["c_req_to"]+"</td>"+
          "<td>"+lists[i]["status"]+"</td>"+
          "</tr></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
   
}

export function GetGraphData(setData){
  
  //alert(JSON.stringify(titleg));
  const options = {
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text: "Result Score : "+setData.titleg+"%"
    },
          height:300,
    data: [{
      type: "pie",
              radius:"50%",
      startAngle: 30,
      toolTipContent: "<b>{label}</b>: {y}%",
      showInLegend: "true",
      legendText: "{label}",
      indexLabelFontSize: 16,
      indexLabel: "{label} - {y}%",
      dataPoints: setData.setData
    }]
  }
  return(
    <CanvasJSChart options = {options} />
  );
}
export function DateTimePicker(refName){
  var refNm= refName.refName;

  const [date, setDate] = useState(new Date());
  const handleChange = date => setDate(date);

  const today = new Date();
  let in3Days = new Date();
  in3Days.setDate(in3Days.getDate() + 30);
  return (
    <DatePicker id={refNm} name={refNm}  className="form-control" 
    selected={date}
    // timeFormat={"H"}
    onChange={handleChange}
    minTime={"10"}
    maxTime={"17"}
    minDate={today}
    maxDate={in3Days}
    timeIntervals={60}
    hintText="Weekends Disabled" 
    // shouldDisableDate={disableWeekends}
  
    showTimeSelect
    dateFormat="dd-MMM-Y hh"
  />
  );
  

}
export function customeSortable(items){
  
}

export function TableDatePicker(refName) {
   //alert(JSON.stringify(refName.refName));
   var refNm= refName.refName;
   const [date, setDate] = useState(new Date());
  
   return (
     <DatePicker className="form-control no-border" dateFormat="dd/MM/yyyy" id={refNm} name={refNm}  format='dd-MM-yyyy' selected={date} onChange={date => setDate(date)} />
   );
  }

export const SetResponseDiv=(divName,respTxt)=>{
   $("#"+divName).html(respTxt);
   return "done";
}

export const defaultClick=(obCls,appliedString)=>{
   $(appliedString).click(function(){
   var appFunction =$(this).attr("attEvt");
      obCls[appFunction]("test");
   });
   //return obCls[clFunction]();
}
export const GetCurrentDtTime=()=>{
  var currentDate=new Date();
  return currentDate.toJSON().slice(0,19);
}
export const SetDate=(date)=> {
   //z = $(date).attr('value');
  
   var today = new Date();
   var dd = today.getDate();
   var mm = today.getMonth() + 1; //January is 0!

   var yyyy = today.getFullYear();
   if (dd < 10) { dd = '0' + dd }
   if (mm < 10) { mm = '0' + mm }
   //today = yyyy + '-' + mm + '-' + dd;
   today = dd + '/' + mm + "/" + yyyy;
   //today=mm+"/"+dd+"/"+yyyy;
   //alert("check== " + today);
   $(date).text(today);
   //$(date).datepicker({dateFormate:'dd/mm/yy'}).val();
}
export const taskDate=(dateMilli)=> {
  var d = (new Date(dateMilli) + '').split(' ');
  d[2] = d[2] + ',';

  return [d[0], d[1], d[2], d[3]].join(' ');
}
export const GetImgCert=(obCls,frmNm,curl,resfun)=>
{
   var options={
      url     : appUrl+curl,
      success : function(data)
      {
        var resData=data.documentElement.childNodes[0].nodeValue;
       
        var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
       var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
      
         return obCls[resfun](checkData,checkMsg);
          
   },
      error: function(data) {
      
         return obCls[resfun](0,"done");
       }
     };
  
   $(frmNm).ajaxSubmit( options );
}
export const getSearchOutPut=(mainDiv,childDiv,searchText)=>{
  var cont=document.getElementById(mainDiv);
  var childList=cont.getElementsByClassName(childDiv);
  for(var i=0;i<childList.length;i++){
    var fromSer=childList[i].querySelector(".timeline-item");
    /*
    var index = fromSer.innerText.toUpperCase().indexOf(searchText.toUpperCase());
  if (index >= 0) { 
    //fromSer.innerHTML = fromSer.innerText.substring(0,index) + "<span class='highlight'>" +fromSer.innerText.substring(index,index+fromSer.innerText.length) + "</span>" + fromSer.innerText.substring(index + fromSer.innerText.length);
   //inputText.innerHTML = innerHTML;
  }
  */

    if(fromSer.innerText.toUpperCase().indexOf(searchText.toUpperCase())>-1){
         childList[i].style.display="";
    }else{
     childList[i].style.display="none";
    }
  }
}
export const RespBkMthdMapp=(obCls,htCls,cBack,groupwhen="")=>{

  if(htCls==="select_target"){
    $('.select_target').unbind('click');
    $(".select_target").on('click',function(){
      //let attrId=$(this).attr("id");
      
     //alert("i m in click");

      var getCallFunc= cBack;
      if(getCallFunc===""){
        getCallFunc= $(this).attr("resp_dest");
      }
  
      var refId=$(this).val();
      return obCls[getCallFunc](refId);

    })
    /*
    $('.select_target').unbind('change');

    $(".select_target").change(function () {
      var getCallFunc= cBack;
      if(getCallFunc===""){
        getCallFunc= $(this).attr("resp_dest");
      }
  
      var refId=$(this).val();
      return obCls[getCallFunc](refId);
     });
     */
  }
  

  if(groupwhen==="2"){
    $("."+htCls).click(function(){
      var refId=$(this).attr("param1");
      return obCls[cBack](refId);
   });
  }
  if(htCls==="_setSearchKey"){
    $("._setSearchKey").keyup(function(e){
      var refId= $(this).val();
         return obCls[cBack](refId);
    });
  }
  if(htCls==="filebr_name"){
   // $('#customFile').on('change', function(){ files = $(this)[0].files; name = ''; for(var i = 0; i < files.length; i++){ name += '\"' + files[i].name + '\"' + (i != files.length-1 ? ", " : ""); } $(".custom-file-label").html(name); });
   
  }
  if(htCls==="_setbackparamFile"){
    $("._setbackparamFile").click(function(){
      var refId=$(this).attr("param1");
      return obCls[cBack](refId);
   });
  }
  if(htCls==="_setbackparam"){
    $("._setbackparam").click(function(){
      var refId=$(this).attr("param1");
      return obCls[cBack](refId);
   });
  }
  if(htCls==="select_file_ch"){
    $(".select_file_ch").change(function () {
    
     var nmFile= $(this).attr("param1");
     var URL = window.URL || window.webkitURL;
       var vidFile=this.files[0];
       var fileURL = URL.createObjectURL(vidFile);
      document.getElementById(nmFile).src=fileURL;
    });
  }
 if(htCls==="hid_file_ch"){
  var tabcontent = document.getElementsByClassName(htCls);
  return tabcontent;
  /*
  for(var t=0;t<tabcontent.length;t++){
   //alert("testing=="+tabcontent[t].value);
   var fileAttr=tabcontent[t].getAttribute("param1");
   return fileAttr;
   //alert(fileAttr+"=="+tabcontent[t].value);
   //document.getElementById(fileAttr).value=tabcontent[t].value;
  }
  */
 } 
}

export const displayQuest=(index,divClsName)=>{
  var tabcontent = document.getElementsByClassName(divClsName);
  for (var i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  if(index!=-1){
    tabcontent[index].style.display="block";
  }
  
}
export const getAttemptQuestions=(lists)=>{
  var strQuet="";
  var getLists=JSON.parse(lists);
  var count=0;
  //alert(getLists.length);
  for(var i=0;i<getLists.length;i++){
    count=i+1;
   // var obList=$data['user_list'][$qk];
  var divName="step"+(count);
  var nextDiv="step"+(count+1);
  var preDiv="step"+(count-1);
  
  //var cName=getLists[i]["id"]+"__Q";
  //var hName= getLists[i]["id"]+"__H";
  //var correctAns=getLists[i]["col6"];
var wrongImg="<img alt=\"wrong\" src=\"/doc-red-sign.jpg\"/>";
var rightImg="<img alt=\"right\" src=\"/doc-green-sign.jpg\"/>";
var lbCustA="custom-control-label";
var lbCustB="custom-control-label";
var lbCustC="custom-control-label";
var lbCustD="custom-control-label";


var corColorA="";
var corColorB="";
var corColorC="";
var corColorD="";
if(getLists[i]["col3"]=="a"){
  corColorA= " correct_ans_color";
}else if(getLists[i]["col3"]=="b"){
  corColorB= " correct_ans_color";
}else if(getLists[i]["col3"]=="c"){
  corColorC= " correct_ans_color";
}else if(getLists[i]["col3"]=="d"){
  corColorD= " correct_ans_color";
}

if(getLists[i]["col2"]==getLists[i]["col3"]){
   if(getLists[i]["col2"]=="a"){
     lbCustA="custome-lable-right";
   }else if(getLists[i]["col2"]=="b"){
     lbCustB="custome-lable-right";
   }else if(getLists[i]["col2"]=="c"){
     lbCustC="custome-lable-right";
   }else if(getLists[i]["col2"]=="d"){
     lbCustD="custome-lable-right";
   }
}else{
  if(getLists[i]["col2"]=="a"){
    lbCustA="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="b"){
    lbCustB="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="c"){
    lbCustC="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="d"){
    lbCustD="custome-lable-wrong";
  }
}
    strQuet+="<div id=\""+divName+"\" style=\"display:block\"  class=\"tabcontent card border p-3 mt-3\">"+
    "<p class=\"ss-font-style-2\"><i className=\"icofont-listing-number\"></i>"+"Q."+count+")"+getLists[i]["question"]+"?"+"</p>"+
    "<div class=\"w-100\"></div>"+
    "<ul class=\"list-group\">"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustA+" "+corColorA+"\">"+getLists[i]["opt_a"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustB+" "+corColorB+"\">"+getLists[i]["opt_b"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustC+" "+corColorC+"\" for=\""+"c"+"\">"+getLists[i]["opt_c"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustD+" "+corColorD+"\" for=\""+"d"+"\">"+getLists[i]["opt_d"]+"</label>"+
        "</div>"+
      "</li>"+
    "</ul>"+
  "</div>";
  }
  return strQuet;
}



export const getQuestions=(lists)=>{
  var strQuet="";
  var getLists=JSON.parse(lists);
  var count=0;
  for(var i=0;i<getLists.length;i++){
    count=i+1;
   // var obList=$data['user_list'][$qk];
  var divName="step"+(count);
  var nextDiv="step"+(count+1);
  var preDiv="step"+(count-1);
  
  var cName=getLists[i]["id"]+"__Q";
  var hName= getLists[i]["id"]+"__H";
  var correctAns=getLists[i]["col6"];

    strQuet+="<div id=\""+divName+"\" style=\"display:none\"  class=\"tabcontent card border p-3 mt-3\">"+
    "<p class=\"ss-font-style-2\"><i className=\"icofont-listing-number\"></i>"+"Q."+count+")"+getLists[i]["col1"]+"?"+"</p>"+
    "<div class=\"w-100\"></div>"+
    "<ul class=\"list-group\">"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control custom-radio\">"+
          "<input type=\"radio\" id=\""+cName+"a"+"\" name=\""+cName+"\" value=\"a\" class=\"custom-control-input\" />"+
          "<label class=\"custom-control-label\" for=\""+cName+"a"+"\">"+getLists[i]["col2"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control custom-radio\">"+
          "<input type=\"radio\" id=\""+cName+"b"+"\" name=\""+cName+"\" value=\"b\" class=\"custom-control-input\" />"+
          "<label class=\"custom-control-label\" for=\""+cName+"b"+"\">"+getLists[i]["col3"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control custom-radio\">"+
          "<input type=\"radio\" id=\""+cName+"c"+"\" name=\""+cName+"\" value=\"c\" class=\"custom-control-input\" />"+
          "<label class=\"custom-control-label\" for=\""+cName+"c"+"\">"+getLists[i]["col4"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control custom-radio\">"+
          "<input type=\"radio\" id=\""+cName+"d"+"\" name=\""+cName+"\" value=\"d\" class=\"custom-control-input\" />"+
          "<label class=\"custom-control-label\" for=\""+cName+"d"+"\">"+getLists[i]["col5"]+"</label>"+
        "</div>"+
        "<input type=\"hidden\" name=\""+hName+"\" value=\""+correctAns+"\">"+
      "</li>"+
    "</ul>"+
  "</div>";
  }
  return strQuet;
}
export const AudioGallery=(lists)=>{
  var getLists=JSON.parse(lists);
  
          var strHeader="<div class=\"shop-category-area pb-100px pt-70px\">"+
          "<div class=\"container\">"+
              "<div class=\"row\">"+
                  "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
                  "<div class=\"section-title heading text-center mb-11\">"+
                  "<h2 class=\"title\">"+""+"</h2>"+
              "</div>"+
                  "<div class=\"shop-bottom-area\">"+
                  "<div class=\"row\">";
           var strFooter="</div></div></div></div></div></div>";
      
          var strResp="";
        for(var i=0;i<getLists.length;i++){
            var strImages=getLists[i]["file_name"];
            if(strImages!=="")
            {
            var arrFiles=strImages.split(',');
            for(var fi=0;fi<arrFiles.length;fi++){
              if(arrFiles[fi].toUpperCase().indexOf(".MP3")!=-1)
              {
              strResp+="<div class=\"timeline video_timeline col-lg-4 col-md-6 col-sm-6 col-xs-6\" data-aos=\"fade-up\" data-aos-delay=\"200\">"+
              "<div class=\"product mb-5\">"+
                  "<div class=\"thumb video_timeline_item timeline-item\">"+
                  "<h3 class=\"timeline-header\"><a href=\"#\">"+getLists[i]["col1"]+"</a></h3>"+
                  "<audio param1=\""+arrFiles[fi]+"\" id=\""+fi+getLists[i]["id"]+"\" class=\"hid_file_ch\" controls></audio>"+
              "</div>"+
                  "</div>"+
          "</div>";
              }
            }
          }
        }
        return strHeader+strResp+strFooter;
}
export const VideoGallery=(lists)=>{
  var getLists=JSON.parse(lists);
  
          var strHeader="<div class=\"shop-category-area pb-100px pt-70px\">"+
          "<div class=\"container\">"+
              "<div class=\"row\">"+
                  "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
                  "<div class=\"section-title heading text-center mb-11\">"+
                  "<h2 class=\"title\">"+""+"</h2>"+
              "</div>"+
                  "<div class=\"shop-bottom-area\">"+
                  "<div class=\"row\">";
           var strFooter="</div></div></div></div></div></div>";
      
          var strResp="";
        for(var i=0;i<getLists.length;i++){
            var strImages=getLists[i]["file_name"];
            if(strImages!=="" && strImages!==null)
            {
            var arrFiles=strImages.split(',');
            for(var fi=0;fi<arrFiles.length;fi++){
              if(arrFiles[fi].toUpperCase().indexOf(".MP4")!=-1)
              {
              strResp+="<div class=\"timeline video_timeline col-lg-4 col-md-6 col-sm-6 col-xs-6\" data-aos=\"fade-up\" data-aos-delay=\"200\">"+
              "<div class=\"product mb-5\">"+
                  "<div class=\"thumb video_timeline_item timeline-item\">"+
                  "<h3 class=\"timeline-header\"><a href=\"#\">"+getLists[i]["col1"]+"</a></h3>"+
                  "<video param1=\""+arrFiles[fi]+"\" id=\""+fi+getLists[i]["id"]+"\" class=\"hid_file_ch\" controls type=\"video/mp4\"></video>"+
              "</div>"+
                  "</div>"+
          "</div>";
              }
            }
          }
        }
        return strHeader+strResp+strFooter;
}
export const ImageGallery=(lists)=>{
  var getLists=JSON.parse(lists);
  
          var strHeader="<div class=\"shop-category-area pb-100px pt-70px\">"+
          "<div class=\"container\">"+
              "<div class=\"row\">"+
                  "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
                  "<div class=\"section-title heading text-center mb-11\">"+
                  "<h2 class=\"title\">"+""+"</h2>"+
              "</div>"+
                  "<div class=\"shop-bottom-area\">"+
                  "<div class=\"row\">";
           var strFooter="</div></div></div></div></div></div>";
      
          var strResp="";
        for(var i=0;i<getLists.length;i++){
            var strImages=getLists[i]["file_name"];
            if(strImages!=="" && strImages!==null)
            {
            var arrFiles=strImages.split(',');
            for(var fi=0;fi<arrFiles.length;fi++){
              if(arrFiles[fi].toUpperCase().indexOf(".JPG")!=-1
              || arrFiles[fi].toUpperCase().indexOf(".JPEG")!=-1
              || arrFiles[fi].toUpperCase().indexOf(".GIF")!=-1
              || arrFiles[fi].toUpperCase().indexOf(".ICO")!=-1
              || arrFiles[fi].toUpperCase().indexOf(".PNG")!=-1)
              {
              strResp+="<div class=\"timeline img_timeline col-lg-3 col-md-6 col-sm-6 col-xs-6\" data-aos=\"fade-up\" data-aos-delay=\"200\">"+
              "<div class=\"product mb-5\">"+
                  "<div class=\"thumb timeline-item img_timeline_item\">"+
                  "<h3 class=\"timeline-header\"><a href=\"#\">"+getLists[i]["col1"]+"</a></h3>"+
                  "<a class=\"gallery-item\" id=\""+getLists[i]["id"]+"_"+fi+"\" href=\"javascript:void(0)\">"+"<img param2=\""+getLists[i]["id"]+"_"+fi+"\" param1=\""+arrFiles[fi]+"\" height=\"100\" width=\"150\" class=\"hid_file_ch\" alt=\"...\"/> </a>"+
                       "</div>"+
              "</div>"+
          "</div>";
              }
            }
          }
        }
        return strHeader+strResp+strFooter;
}

export const TimeLineImg=(lists)=>{
  var getLists=JSON.parse(lists);
  var strResp="";
  var strTimeL="";
  var strDivText="";
  var strImgText="";
  var strVidText="";
  var strOth="";
  for(var i=0;i<getLists.length;i++){
     strTimeL="";
     strDivText="";
     strImgText="";
     strVidText="";
     strOth="";
     var createdDate = new Date(getLists[i]["entry_date"]);
     if(getLists[i]["file_name"].toUpperCase().indexOf(".JPG")!=-1 || getLists[i]["file_name"].toUpperCase().indexOf(".JPEG")!=-1
     || getLists[i]["file_name"].toUpperCase().indexOf(".GIF")!=-1
     || getLists[i]["file_name"].toUpperCase().indexOf(".ICO")!=-1
     || getLists[i]["file_name"].toUpperCase().indexOf(".PNG")!=-1)
     {
    strTimeL="<div class=\"timeline\"><div class=\"time-label\">"+
     "<span class=\"bg-primary\">"+getLists[i]["entry_date"]+"</span>"+
   "</div>";
   strTimeL+="<div>"+
   "<i class=\"fa fa-comments bg-yellow\"></i>"+
   "<div class=\"timeline-item\">"+
   "<span class=\"time\"><i className=\"fa fa-clock\"></i> for self</span>"+
   "<h3 class=\"timeline-header\"><a href=\"#\">"+getLists[i]["col1"]+"</a></h3>";
   if(getLists[i]["details"]!==""){
    strDivText="<div class=\"timeline-body\">"+getLists[i]["details"]+"</div>";
   }
   if(getLists[i]["file_name"]!==""){
     var files=getLists[i]["file_name"];
     var arrFiles=files.split(',');
     for(var fi=0;fi<arrFiles.length;fi++){
       //alert(arrFiles[fi]);
       if(arrFiles[fi].toUpperCase().indexOf(".JPG")!=-1 || 
       arrFiles[fi].toUpperCase().indexOf(".PNG")!=-1 || arrFiles[fi].toUpperCase().indexOf(".JPEG")!=-1 || 
       arrFiles[fi].toUpperCase().indexOf(".ICO")!=-1 || arrFiles[fi].toUpperCase().indexOf(".GIF")!=-1 ){
         if(strImgText==""){
           strImgText="<div class=\"timeline-body\">";
         }
        strImgText+="<a class=\"gallery-item\" id=\""+getLists[i]["id"]+"_"+fi+"\" href=\"javascript:void(0)\">"+"<img param2=\""+getLists[i]["id"]+"_"+fi+"\" param1=\""+arrFiles[fi]+"\" height=\"100\" width=\"150\" class=\"hid_file_ch\" alt=\"...\"/> </a>";
       }else if(arrFiles[fi].toUpperCase().indexOf(".MP4")!=-1){
        
        if(strVidText==""){
           strVidText="<div class=\"timeline-body\">";
         }
         strVidText+="<video param1=\""+arrFiles[fi]+"\" id=\""+fi+getLists[i]["id"]+"\" class=\"hid_file_ch\" controls type=\"video/mp4\"></video>"; 
         //strVidText+="<a class=\"_setbackparam\" href=\"javascript:void(0)\" param1=\""+arrFiles[fi]+"\"><i class=\"fa fa-play-circle-o fa-2x\" aria-hidden=\"true\"></i>"+arrFiles[fi]+"</a>";
       }else{
          if(strOth==""){
            strOth="<div class=\"timeline-body\">";
          }
          strOth+="<a class=\"_setbackparam\" href=\"javascript:void(0)\" param1=\""+arrFiles[fi]+"\">"+arrFiles[fi]+"</a>";
       }
       
     }
     if(strImgText!==""){
       strImgText+="</div>";
     }
     if(strVidText!==""){
       strVidText+="</div>";
     }
     if(strOth!==""){
       strOth+="</div>";
     }

   }
   
   strResp+=strTimeL+strDivText+strOth+strImgText+strVidText;
   strResp+="</div></div></div>";
  }
  }
  return strResp;
}
export const TimeLineVideo=(lists)=>{
  var getLists=JSON.parse(lists);
  var strResp="";
  var strTimeL="";
  var strDivText="";
  var strImgText="";
  var strVidText="";
  var strOth="";
  for(var i=0;i<getLists.length;i++){
     strTimeL="";
     strDivText="";
     strImgText="";
     strVidText="";
     strOth="";
     var createdDate = new Date(getLists[i]["entry_date"]);
     if(getLists[i]["file_name"].toUpperCase().indexOf(".MP4")!=-1)
     {
    strTimeL="<div class=\"timeline\"><div class=\"time-label\">"+
     "<span class=\"bg-primary\">"+getLists[i]["entry_date"]+"</span>"+
   "</div>";
   strTimeL+="<div>"+
   "<i class=\"fa fa-comments bg-yellow\"></i>"+
   "<div class=\"timeline-item\">"+
   "<span class=\"time\"><i className=\"fa fa-clock\"></i> for self</span>"+
   "<h3 class=\"timeline-header\"><a href=\"#\">"+getLists[i]["col1"]+"</a></h3>";
   if(getLists[i]["details"]!==""){
    strDivText="<div class=\"timeline-body\">"+getLists[i]["details"]+"</div>";
   }
   if(getLists[i]["file_name"]!==""){
     var files=getLists[i]["file_name"];
     var arrFiles=files.split(',');
     for(var fi=0;fi<arrFiles.length;fi++){
       //alert(arrFiles[fi]);
       if(arrFiles[fi].toUpperCase().indexOf(".JPG")!=-1 || 
       arrFiles[fi].toUpperCase().indexOf(".PNG")!=-1 || arrFiles[fi].toUpperCase().indexOf(".JPEG")!=-1 || 
       arrFiles[fi].toUpperCase().indexOf(".ICO")!=-1 || arrFiles[fi].toUpperCase().indexOf(".GIF")!=-1 ){
         if(strImgText==""){
           strImgText="<div class=\"timeline-body\">";
         }
        strImgText+="<a class=\"gallery-item\" id=\""+getLists[i]["id"]+"_"+fi+"\" href=\"javascript:void(0)\">"+"<img param2=\""+getLists[i]["id"]+"_"+fi+"\" param1=\""+arrFiles[fi]+"\" height=\"100\" width=\"150\" class=\"hid_file_ch\" alt=\"...\"/> </a>";
       }else if(arrFiles[fi].toUpperCase().indexOf(".MP4")!=-1){
        
        if(strVidText==""){
           strVidText="<div class=\"timeline-body\">";
         }
         strVidText+="<video param1=\""+arrFiles[fi]+"\" id=\""+fi+getLists[i]["id"]+"\" class=\"hid_file_ch\" controls type=\"video/mp4\"></video>"; 
         //strVidText+="<a class=\"_setbackparam\" href=\"javascript:void(0)\" param1=\""+arrFiles[fi]+"\"><i class=\"fa fa-play-circle-o fa-2x\" aria-hidden=\"true\"></i>"+arrFiles[fi]+"</a>";
       }else{
          if(strOth==""){
            strOth="<div class=\"timeline-body\">";
          }
          strOth+="<a class=\"_setbackparam\" href=\"javascript:void(0)\" param1=\""+arrFiles[fi]+"\">"+arrFiles[fi]+"</a>";
       }
       
     }
     if(strImgText!==""){
       strImgText+="</div>";
     }
     if(strVidText!==""){
       strVidText+="</div>";
     }
     if(strOth!==""){
       strOth+="</div>";
     }

   }
   
   strResp+=strTimeL+strDivText+strOth+strImgText+strVidText;
   strResp+="</div></div></div>";
  }
  }
  return strResp;
}
export const TimeLine=(lists)=>{
 // alert(lists);
  var getLists=JSON.parse(lists);
  var strResp="";
  var strTimeL="";
  var strDivText="";
  var strImgText="";
  var strVidText="";
  var strAudioText="";
  var strOth="";
  for(var i=0;i<getLists.length;i++){
     strTimeL="";
     strDivText="";
     strImgText="";
     strVidText="";
     strAudioText="";
     strOth="";
     var createdDate = new Date(getLists[i]["entry_date"]);
    
    strTimeL="<div class=\"timeline\"><div class=\"time-label\">"+
     "<span class=\"bg-primary\">"+getLists[i]["entry_date"]+"</span>"+
   "</div>";
   strTimeL+="<div>"+
   "<i class=\"fa fa-comments bg-yellow\"></i>"+
   "<div class=\"timeline-item\">"+
   "<span class=\"time\"><i className=\"fa fa-clock\"></i>"+getLists[i]["category"]+"</span>"+
   "<h3 class=\"timeline-header\"><a href=\"#\">"+getLists[i]["col1"]+"</a></h3>";
   if(getLists[i]["details"]!==""){
    strDivText="<div class=\"timeline-body\">"+getLists[i]["details"]+"</div>";
   }
   if(getLists[i]["file_name"]!=="" && getLists[i]["file_name"]!==null){
     var files=getLists[i]["file_name"];
     var arrFiles=files.split(',');
     for(var fi=0;fi<arrFiles.length;fi++){
       //alert(arrFiles[fi]);
       if(arrFiles[fi].toUpperCase().indexOf(".JPG")!=-1 || 
       arrFiles[fi].toUpperCase().indexOf(".PNG")!=-1 || arrFiles[fi].toUpperCase().indexOf(".JPEG")!=-1 || 
       arrFiles[fi].toUpperCase().indexOf(".ICO")!=-1 || arrFiles[fi].toUpperCase().indexOf(".GIF")!=-1 ){
         if(strImgText==""){
           strImgText="<div class=\"timeline-body\">";
         }
        strImgText+="<a class=\"gallery-item\" id=\""+getLists[i]["id"]+"_"+fi+"\" href=\"javascript:void(0)\">"+"<img param2=\""+getLists[i]["id"]+"_"+fi+"\" param1=\""+arrFiles[fi]+"\" height=\"100\" width=\"150\" class=\"hid_file_ch\" alt=\"...\"/> </a>";
       }else if(arrFiles[fi].toUpperCase().indexOf(".MP4")!=-1){
        
        if(strVidText==""){
           strVidText="<div class=\"timeline-body\">";
         }
         strVidText+="<video param1=\""+arrFiles[fi]+"\" id=\""+fi+getLists[i]["id"]+"\" class=\"hid_file_ch\" controls type=\"video/mp4\"></video>"; 
         //strVidText+="<a class=\"_setbackparam\" href=\"javascript:void(0)\" param1=\""+arrFiles[fi]+"\"><i class=\"fa fa-play-circle-o fa-2x\" aria-hidden=\"true\"></i>"+arrFiles[fi]+"</a>";
       }else if(arrFiles[fi].toUpperCase().indexOf(".MP3")){
         if(strAudioText==""){
          strAudioText="<div class=\"timeline-body\">";
         }
        strAudioText+="<audio param1=\""+arrFiles[fi]+"\" id=\""+fi+getLists[i]["id"]+"\" class=\"hid_file_ch\" controls ></audio>"; 
       }
       else{
          if(strOth==""){
            strOth="<div class=\"timeline-body\">";
          }
          strOth+="<a class=\"_setbackparam\" href=\"javascript:void(0)\" param1=\""+arrFiles[fi]+"\">"+arrFiles[fi]+"</a>";
       }
       
     }
     if(strImgText!==""){
       strImgText+="</div>";
     }
     if(strVidText!==""){
       strVidText+="</div>";
     }
     if(strAudioText!==""){
      strAudioText+="</div>";
    }
     if(strOth!==""){
       strOth+="</div>";
     }

   }
   
   strResp+=strTimeL+strDivText+strOth+strImgText+strVidText+strAudioText;
   strResp+="</div></div></div>"
  }
  return strResp;
}

export const getAccessRolesNew=(roles)=>{
  var getMenu="";
  const listFltr = d => (d.display_option === 'menu' || d.display_option === 'top_menu') && d.parent_id == 0;
  var topMenusList = roles.filter(listFltr);
  //alert(topMenusList.length);
  for (var i = 0; i < topMenusList.length; i++) {
      const childFltr = d => d.parent_id === topMenusList[i]["id"] && d.display_option !== 'desk'
      var childMenuList = roles.filter(childFltr);
      var getChMenu = "";
      //alert(childMenuList.length + " = " + topMenusList[i]["id"]);
      if (childMenuList.length === 0) {
          getMenu += " <li class=\"nav-item has-treeview\">" +
              "<a href = \"" + topMenusList[i]["red_link"] + "\" class=\"nav-link\" >"+topMenusList[i]["description"] + topMenusList[i]["name"] + "</a>" +
              "</li>";
      } else {
          getMenu += "<li class=\"nav-item dropdown\">" +
              "<a class=\"nav-link dropdown-toggle\" href = \"#\" id=\"navbarDropdown\" role=\"button\" data-toggle=\"dropdown\" aria haspopup=\"true\" aria-expanded=\"false\">" + topMenusList[i]["col1"] + "</a>"
          getChMenu = "<div class=\"dropdown-menu\" aria-labelledby=\"navbarDropdown\">";
          for (var ch = 0; ch < childMenuList.length; ch++) {
              getChMenu += "<a class=\"dropdown-item\" href=\"" + childMenuList[ch]["col3"]+"?rf="+atob(childMenuList[ch]["id"]) + "\">" + childMenuList[ch]["col1"] + "</a>";
          }
          getChMenu += "</div>";
          getMenu += getChMenu + "</li>";
      }

      // getMenu += topMenusList[i]["col1"];
  }
  return getMenu;
 
}
export const CallMapMethodRef=(obCls,frmValues,refObj,othInfo="")=>{
  var curl_type=frmValues['curl_type'];

    //alert("check url == "+curl_type);
    var divRes="";
    var getCallFunc=""
    if(frmValues['res_name'])
    {
      divRes=frmValues['res_name'];
    }

    if(frmValues['res_func'])
    {
      getCallFunc=frmValues['res_func'];
    }
        var ss=frmValues;
        //var xs = "{objs:" + JSON.stringify(ss) + "}";
        var xs=JSON.stringify(ss);
       //alert(xs);
         /*check for Socket Communication */
         let isSocket=false;
         if(typeof obCls.objSocket!=="undefined"){
           if(obCls.objSocket.isRun){
             isSocket=true;
           }
         }
         if(!isSocket){
          $.ajax({
            crossDomain:true,
                type: "POST",
                url: appUrl+curl_type,
                data:xs,
                dataType:"json",
                contentType: "application/json",
             success: function (data) {
              //alert(data);   
              //ajaxindicatorstop();
                 // return data;
                 if(divRes!="")
                 {
                     if ($("#" + divRes).length!=0)
                  $("#"+divRes).html(data);
                 }
                 if(getCallFunc!="")
                 {
                  var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                  var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                  //  eval(getCallFunc);
                  //alert(checkData);
                 return obCls[getCallFunc](checkData,checkMsg,refObj,othInfo);
                    }
                   
                },
              error:function(data){
                if(divRes!="")
                {
                    if ($("#" + divRes).length!=0)
                 $("#"+divRes).html(data);
                }
                if(getCallFunc!="")
                {
                 var checkData=$($($.parseHTML(data.responseText)).filter("#resP")).text();
                 var checkMsg=$($($.parseHTML(data.responseText)).filter("#resPS")).text();
                 //  eval(getCallFunc);
                 //alert(checkData);
                return obCls[getCallFunc](checkData,checkMsg,refObj,othInfo);
                   }
              }
            });
         }else{
          obCls.objSocket.sendRequest(curl_type,xs,function(data){
            var checkData=$($($.parseHTML(data)).filter("#resP")).text();
            var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
            return obCls[getCallFunc](checkData,checkMsg);
          });
         }
        
}
export const CallMapMethod=(obCls,frmValues)=>{
  
   var curl_type=frmValues['curl_type'];

    //alert("check url == "+curl_type);
    var divRes="";
    var getCallFunc=""
    if(frmValues['res_name'])
    {
      divRes=frmValues['res_name'];
    }

    if(frmValues['res_func'])
    {
      getCallFunc=frmValues['res_func'];
    }
        var ss=frmValues;
        //var xs = "{objs:" + JSON.stringify(ss) + "}";
  
        var xs=JSON.stringify(ss);
  
        /*check for Socket Communication */
        let isSocket=false;
        if(typeof obCls.objSocket!=="undefined"){
          if(obCls.objSocket.isRun){
            isSocket=true;
          }
        }
  
  
        //alert(xs);
        if(!isSocket){
          $.ajax({
            crossDomain:true,
                type: "POST",
                url: appUrl+curl_type,
                data:xs,
                dataType:"json",
                contentType: "application/json",
                
             success: function (data) {
             //alert(JSON.stringify(data));   
              //ajaxindicatorstop();
                 // return data;
                 if(divRes!="")
                 {
                     if ($("#" + divRes).length!=0)
                  $("#"+divRes).html(data);
                 }
                 if(getCallFunc!="")
                 {
                  var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                  var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                  //  eval(getCallFunc);
                  //alert(checkData);
                  //alert(getCallFunc);
                 return obCls[getCallFunc](checkData,checkMsg);
                    }
                   
                },
              error:function(data){
                //alert(JSON.stringify(data));
                if(divRes!="")
                {
                    if ($("#" + divRes).length!=0)
                 $("#"+divRes).html(data);
                }
                if(getCallFunc!="")
                {
                 var checkData=$($($.parseHTML(data.responseText)).filter("#resP")).text();
                 var checkMsg=$($($.parseHTML(data.responseText)).filter("#resPS")).text();
                 //  eval(getCallFunc);
                 //alert(checkData);
                 //alert(getCallFunc);
                return obCls[getCallFunc](checkData,checkMsg);
                   }
              }
            });
        }else{
          obCls.objSocket.sendRequest(curl_type,xs,function(data){
            var checkData=$($($.parseHTML(data)).filter("#resP")).text();
            var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
            return obCls[getCallFunc](checkData,checkMsg);
          });
        }
        


}

export const showHideProcess=(btnClass,showHide)=>{

  var getElement=document.getElementsByClassName(btnClass);
  //alert(getElement.length);
  if(showHide==="hide"){
    for(var i=0;i<getElement.length;i++){
      getElement[i].style.display="none";
      getElement[i].parentElement.innerHTML+="<img height=\"30px\" width=\"30px\" id=\"loader"+i+"\" src=\"loader.gif\" />";
    }
  }else if(showHide==="show"){
    for(var i=0;i<getElement.length;i++){
      getElement[i].style.display="block";
      document.getElementById("loader"+i).remove();
    }
  }
  
}

export const GetImgCertUser=(obCls,frmNm,curl,resfun,btnEffect="btnLoader")=>
{
   
  showHideProcess(btnEffect,"hide");
   /*check for Socket Communication */
   let isSocket=false;
   if(typeof obCls.objSocket!=="undefined"){
     if(obCls.objSocket.isRun){
       isSocket=true;
     }
   }

  if(!isSocket){
    var options={
      url     : appUrl+curl,
      success : function(data)
      {
       
        alert(data);
       // var resData=data.documentElement.childNodes[0].nodeValue;
       var resData=data;
        var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
       var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
       //var userMsg=$($($.parseHTML(resData)).filter("#reParty")).text();
       var userMsg="";
       showHideProcess(btnEffect,"show");  
       return obCls[resfun](checkData,checkMsg,userMsg);
          
   },
      error: function(data) {
         alert("error:="+JSON.stringify(data));
         return obCls[resfun](0,data);
       }
     };
     /*
     var formObject=document.getElementById(frmNm.substr(1,(frmNm.length-1)));
     alert(frmNm.substr(1,(frmNm.length-1)));
     var objAudio=document.getElementsByClassName("submit_audio");
     alert(objAudio.length);
     for(var i=0;i<objAudio.length;i++){
      var reader = new FileReader();
      reader.readAsDataURL(objAudio[i].src);
      reader.onloadend = function() {
        var base64data = reader.result; 
        alert(base64data);
      }
     }
   return;
   */
   $(frmNm).ajaxSubmit( options );
 
  }else{
    alert("Submitting via Socket "+frmNm);
   
    $(frmNm).submit(function(event){
      event.preventDefault();
 
      alert("i m in submit request");
      obCls.objSocket.socketIO.emit('submitPost', $('#Input').val());  
    });
    obCls.objSocket.socketIO.on("subPosResp",function(data){
      var resData=data;
      var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
     var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
     //var userMsg=$($($.parseHTML(resData)).filter("#reParty")).text();
     var userMsg="";
     showHideProcess(btnEffect,"show");  
     return obCls[resfun](checkData,checkMsg,userMsg);
    })
  }
   

}

export const AjaxCallMethod = (obCls,cBack)=>{
/*Section to Return Image Thumbnail */
  $("._brFileThumb").click(function(){
    var getNm=$(this).attr("setTo");
    document.getElementById(getNm).click();
  });
  $(".updImgThumb").change(function(){
    var currentObj=$(this);
    var refBack=$(this).attr("ref_img");
    var refFlName=this.value.split('\\').pop();
    //alert();
    //alert(refBack);
    if (this.files && this.files[0]) {
      //alert("i am read url");
        var reader = new FileReader();
        reader.onload = function (e) {
          //var refBack=$(this).attr("ref_img");
          //alert(refBack);
          //alert(e.target.name);
          return obCls[refBack](e.target.result,refFlName,currentObj);
          //alert($(input).attr("ref_img"));
           // $('.'+$(input).attr("ref_img")).attr('src', e.target.result);
        }
 
        reader.readAsDataURL(this.files[0]);
    }
  });

  $('._brFile').click(function(){
     var getNm=$(this).attr("setTo");
     
     document.getElementById(getNm).click();
     });
  $(".updImg").change(function(){
     readURL(this);
     //alert("A file has been selected.");
 });
 $('.select_target').unbind('change');
 $(".select_target").change(function () {
  var getCallFunc= $(this).attr("resp_dest");
  var refId=$(this).val();
  //alert("ai on change "+getCallFunc+","+refId);
  return obCls[getCallFunc](refId);
 });
 

   $('._btnSetColor').click(function () {
    //alert("i m in click");
     //var m_type=$("#m_type").val();
   //var curl_type=$("#curl_type").val();
 //$("#modal_login").serialize();
  var ssFrm=$(this).closest('form');
  var m_type= $(ssFrm).find('input[name="m_type"]').val();
  var curl_type=$(ssFrm).find('input[name="m_type"]').val();
  var getCallFunc=$(ssFrm).find('input[name="res_func"]').val();
   var ss = $(this).closest('form').serializeArray();
   
   //var xs = "{objs:" + JSON.stringify(ss) + "}";
   var xs=JSON.stringify(ss);
   //alert(xs);
  if(!getCallFunc){
getCallFunc="";

   }
   //alert(xs);
  
   //ajaxindicatorstart('loading data.. please wait..');
//alert(getCallFunc);
  //var ss=$("#"+fName).serialize();
        $.ajax({
          crossDomain: true,
               type: "POST",
               dataType:"json",
              contentType: "application/json",
            url: appUrl + curl_type,
            data: xs,
             success: function (data) {
               //alert("data="+data);
                //ajaxindicatorstop();
                   //alert("Registration Completed Successfully ");
                   // document.getElementById("frmRegister").reset();
                   //location.reload();
                    if(getCallFunc!="")
                    {
                       var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                       var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                 //  eval(getCallFunc);
                 //alert(checkData);
                       return obCls[getCallFunc](checkData,checkMsg);
                    }else
                    {
                      //parent.$.colorbox.close();
                   }
                   
                
               },
            error:function(data){
             // alert(JSON.stringify(data));
              if(getCallFunc!="")
                    {
                       var checkData=$($($.parseHTML(data.responseText)).filter("#resP")).text();
                       var checkMsg=$($($.parseHTML(data.responseText)).filter("#resPS")).text();
                 //alert(checkData);
                       //  eval(getCallFunc);
                 //alert(checkData);
                       return obCls[getCallFunc](checkData,checkMsg);
                    }else
                    {
                      //parent.$.colorbox.close();
                   }
                   
            }
           })

});
}
export default AjaxCallMethod