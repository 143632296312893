import $ from 'jquery';
import React from 'react';
import OrgChart from '@balkangraph/orgchart.js';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,drawChart} from '../function_lib/common_lib';
import Header from './header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/Bhandarkarlogo.png';
import defaultPic from '../assets/images/9.png';


let loginUser="";
let userObject="";
class Post_Timeline extends React.Component{
    render(){
        return(<div>
            <Header/>
            <Side_Bar/>
            <div className="content-wrapper">
            <section className="content">
             <div className="container-fluid">
             <div class="card-body">
             <div class="active tab-pane" id="activity">

<div class="post">
  <div class="user-block">
    <img class="img-circle img-bordered-sm" src={defaultPic} alt="user image" />
    <span class="username">
      <a href="#">Jonathan Burke Jr.</a>
      <a href="#" class="float-right btn-tool"><i class="fa fa-times"></i></a>
    </span>
    <span class="description">Shared publicly - 7:30 PM today</span>
  </div>
  
  <p>
    Lorem ipsum represents a long-held tradition for designers,
    typographers and the like. Some people hate it and argue for
    its demise, but others ignore the hate as they create awesome
    tools to help create filler text for everyone from bacon lovers
    to Charlie Sheen fans.
  </p>

  <p>
    <a href="#" class="link-black text-sm mr-2"><i class="fa fa-share mr-1"></i> Share</a>
    <a href="#" class="link-black text-sm"><i class="fa fa-thumbs-up mr-1"></i> Like</a>
    <span class="float-right">
      <a href="#" class="link-black text-sm">
        <i class="fa fa-comments mr-1"></i> Comments (5)
      </a>
    </span>
  </p>

  <input class="form-control form-control-sm" type="text" placeholder="Type a comment" />
</div>

<div class="post clearfix">
  <div class="user-block">
    <img class="img-circle img-bordered-sm" src={defaultPic} alt="User Image" />
    <span class="username">
      <a href="#">Sarah Ross</a>
      <a href="#" class="float-right btn-tool"><i class="fa fa-times"></i></a>
    </span>
    <span class="description">Sent you a message - 3 days ago</span>
  </div>
  
  <p>
    Lorem ipsum represents a long-held tradition for designers,
    typographers and the like. Some people hate it and argue for
    its demise, but others ignore the hate as they create awesome
    tools to help create filler text for everyone from bacon lovers
    to Charlie Sheen fans.
  </p>

  <form class="form-horizontal">
    <div class="input-group input-group-sm mb-0">
      <input class="form-control form-control-sm" placeholder="Response" />
      <div class="input-group-append">
        <button type="submit" class="btn btn-danger">Send</button>
      </div>
    </div>
  </form>
</div>



<div class="post">
  <div class="user-block">
    <img class="img-circle img-bordered-sm" src={defaultPic} alt="User Image" />
    <span class="username">
      <a href="#">Adam Jones</a>
      <a href="#" class="float-right btn-tool"><i class="fa fa-times"></i></a>
    </span>
    <span class="description">Posted 5 photos - 5 days ago</span>
  </div>
  
  <div class="row mb-3">
    <div class="col-sm-6">
      <img class="img-fluid" src="../../dist/img/photo1.png" alt="Photo" />
    </div>
    
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-6">
          <img class="img-fluid mb-3" src="../../dist/img/photo2.png" alt="Photo" />
          <img class="img-fluid" src="../../dist/img/photo3.jpg" alt="Photo" />
        </div>
        
        <div class="col-sm-6">
          <img class="img-fluid mb-3" src="../../dist/img/photo4.jpg" alt="Photo" />
          <img class="img-fluid" src="../../dist/img/photo1.png" alt="Photo" />
        </div>
     
      </div>
  
    </div>
  
  </div>
  

  <p>
    <a href="#" class="link-black text-sm mr-2"><i class="fa fa-share mr-1"></i> Share</a>
    <a href="#" class="link-black text-sm"><i class="fa fa-thumbs-up mr-1"></i> Like</a>
    <span class="float-right">
      <a href="#" class="link-black text-sm">
        <i class="fa fa-comments mr-1"></i> Comments (5)
      </a>
    </span>
  </p>

  <input class="form-control form-control-sm" type="text" placeholder="Type a comment" />
</div>

</div>
</div>
                 </div>
                 </section>
            </div>
            </div>)
    }
}
export default Post_Timeline