import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {AjaxCallMethod,CallMapMethod,TableDatePicker} from '../function_lib/common_lib';

import Header from './header';
import Footer from './footer';

let loginUser="";
let userObject="";
let clsObject;
class Create_Company extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){
          clsObject=this;
          $("#gb_token").val(userObject[0].app_user_id);
          AjaxCallMethod(this,"okSuccCNOT");
          clsObject.getCompanyList();
        
      }
      getCompanyList(){
        var map=[];
        map.push({name:"curl_type",value:"getCompanyList"});
        map.push({name:"res_func",value:"callBackNg"});
        map.push({name:"gb_token",value:userObject[0].app_user_id});
        map.push({name:"emp_id",value:userObject[0].id});
        map["res_func"]="callBackNg";
        map["curl_type"]="getCompanyList";
        CallMapMethod(this,map);
      }

      //Relative Methods
      callBackNg(data,msg){
        //alert(data);
      if(data!=="0" && data!==""){
          var listItems=JSON.parse(data);
          var str="";
          for(var i=0;i<listItems.length;i++){
          str+="<tr>"+
          "<td>"+(i+1)+"</td>"+
          "<td>"+listItems[i]["col3"]+"</td>"+
          "<td>"+listItems[i]["col4"]+"</td>"+
          "<td>"+listItems[i]["col5"]+"</td>"+
          "<td>"+listItems[i]["col6"]+"</td>"+
          "<td>"+listItems[i]["col7"]+"</td>"+
          "<td>"+listItems[i]["col8"]+"</td>"+
          "<td>"+listItems[i]["entry_date"]+"</td>"+
          "</tr>";
          }
          $("#tbData").html(str);
          $(".total_rec").html(listItems.length);
          $(".total_active_rec").html(listItems.length);
      }
    }
      okSuccCNOT(data,datares){
         // alert(datares);
        if(data!="0" && data!==""){
            $("#frmCreateComp").find("input[type=text]").val("");
          $("#getReponse").html("Company Created Successfully..");
          clsObject.getCompanyList();
        }else{
          $("#getReponse").html("Invalid UserName or Password !");
        }
    }
    render(){
        return(<div>
<Header/>
        <div class="content-wrapper">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header d-flex p-0">
                        <h3 class="card-title p-3"></h3>
                        <ul class="nav nav-pills ml-auto p-2">
                            <li class="nav-item"><a class="nav-link active ordStatus" href="#tab_1" ref_node="Pending" data-toggle="tab">Created A/c</a></li>
                            <li class="nav-item"><a class="nav-link ordStatus" href="#tab_2" ref_node="Approved" data-toggle="tab">Suspended A/c</a></li>
                            <li class="nav-item"><a class="nav-link ordStatus" href="#tab_2" ref_node="Rejected" data-toggle="tab">Expired A/c</a></li>
                            <li class="nav-item"><a class="nav-link ordStatus" href="#tab_3" ref_node="0" data-toggle="tab">All</a></li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <input type="hidden" name="viewTMapp_manual" id="viewTMapp_manual" value="#" />
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab_1">
                                <section class="content">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title">Add New</h3>
    
                                                        <div class="card-tools">
                                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="card-body p-0">
                                                        <ul class="nav nav-pills flex-column">
                                                            <li class="nav-item">
                                                                <a href="javascript:void(0);" data-toggle="modal" data-target="#divCompany" _hHeight="60%" _hWidth="70%" setl="#" class="btn _hcolorspp btn-block btn-default btn-sm">Add New</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title">Status within Duration</h3>
    
                                                        <div class="card-tools">
                                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="card-body p-0">
                                                        <ul class="nav nav-pills flex-column">
                                                            <li class="nav-item active">
                                                                <a href="#" class="nav-link">
                                                                    <i class="fa fa-building"></i> Total Accounts
                                                                    <span class="badge bg-primary float-right total_rec">0</span>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item active">
                                                                <a href="#" class="nav-link">
                                                                    <i class="fa fa-building"></i> Active Accounts
                                                                    <span class="badge bg-primary float-right total_active_rec">0</span>
                                                                </a>
                                                            </li>
                                                           
                                                            <li class="nav-item">
                                                                <a href="#" class="nav-link">
                                                                    <i class="fa fa-times-circle"></i> Suspended.
                                                                    <span class="badge bg-warning float-right total_suspended_rec">0</span>
                                                                </a>
                                                            </li>
    
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="card card-primary card-outline">
                                                    <div class="card-header">
                                                        <h3 class="card-title">
                                                            <i class="fa fa-edit"></i>
                                                            Create New Company
                                                        </h3>
                                                        <div class="card-tools">
                                                            <div class="input-group input-group-sm" style={{width: "150px"}}>
                                                                <a href="javascript:void(0);" id="searchEvent" name="searchEvent" class="btn btn-block btn-default btn-sm">Search</a>
                                                            </div>
                                                        </div>
    
                                                        <div class="card-tools">
                                                            <div class="input-group input-group-sm" style={{width: "150px"}}>
    
                                                                <input type="text" name="table_search" class="form-control float-right" placeholder="Search" />
                                                            </div>
                                                        </div>
    
    
                                                        <div class="card-tools">
                                                            <div class="input-group input-group-sm" style={{width: "150px"}}>
                                                                <select class="form-control select_target_man" id="som" name="som">
                                                                    <option value="0">-Select-</option>
                                                                </select>
                                                            </div>
                                                        </div>
    
                                                    </div>
                                                    <div className="card-body">
                                                        <table className="table table-bordered table-striped table-ms">
                                                            <thead>
                                                                <tr style={{backgroundColor:"#FC766AFF"}}>
                                                                    <th>Company Id</th>
                                                                    <th>Company Name</th>
                                                                    <th>Contact Person</th>
                                                                    <th>Contact No.</th>
                                                                    <th>Address</th>
                                                                    <th>City</th>
                                                                    <th>Alloted Enrollment</th>
                                                                    <th>Creation Date</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="tbData">
                                                            </tbody>
                                                        </table>
                                                        <div class="row" id="tbData1">
    
    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    <div className="modal fade bd-example-modal-xl"  id="divCompany" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Company A/c Master</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreateComp">
      <input type="hidden" name="curl_type" id="curl_type" value="createCompany" />  
      <input type="hidden" name="type" id="type" value="1" />
                <input type="hidden" name="m_type" id="m_type" value="createCompany" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
                <input type="hidden" name="gb_token" id="gb_token" value="0"/>
      <div className="modal-body">
      <div className="row">
      <div className="col-lg-3 col-md-3 col-sm-6 col-12">
      <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Company Name</label>
            <input type="text" className="form-control" id="col3" name="col3" />
          </div> 
		         
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Person Name</label>
            <input type="text" className="form-control" id="col4" name="col4" />
          </div>
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
         <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Contact No</label>
            <input type="text" className="form-control" id="col5" name="col5" />
          </div>
         </div>
         
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Address</label>
            <input type="text" className="form-control" id="col6" name="col6" />
          </div>          
         </div>
         <div className="col-lg-2 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">City</label>
            <input type="text" className="form-control" id="col7" name="col7" />
          </div>          
         </div>
         <div className="col-lg-2 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">No of Employee</label>
            <input type="text" className="form-control" id="col8" name="col8" />
          </div>          
         </div>
         <div className="col-lg-2 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Alloted No of Users</label>
            <input type="text" className="form-control" id="col9" name="col9" />
          </div>          
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Assign Quiz</label>
            <input type="text" className="form-control" id="col10" name="col10" />
          </div>          
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-4 font-sm">Creation Date</label>
            <br></br>
            <TableDatePicker refName="datetmp"/>
            
          </div>          
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-4 font-sm">Assign UserName</label>
            <input type="text" className="form-control" id="col1" name="col1" />
          </div>          
         </div>
         <div className="col-lg-3 col-md-3 col-sm-6 col-12">
		  <div className="form-group input-group-sm">
            <label for="recipient-name" className="col-form-label ss-font-style-4 font-sm">Password</label>
            <input type="text" className="form-control" id="col2" name="col2" />
          </div>          
         </div>

      </div>
      </div>
      
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
    </div>
    )
    }
}
export default Create_Company;