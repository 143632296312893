import $, { post } from 'jquery';
import React from 'react';
import {GetImgCertUser,CallMapMethod} from '../function_lib/common_lib';
let clsObject;
class Add_New_Call extends React.Component{

  /* Get Parent Directories for Call */
  getDirectories() {
    var getDir = [];
    getDir.push({ name: "curl_type", value: "getDirectories" });
    getDir.push({ name: "res_func", value: "getBackDir" });
    getDir.push({ name: "dir_name", value: "" });
    getDir.push({ name: "type_id", value: "21" });
    // getDir.push({name:"ref2_id",value:"2"});
    getDir["res_func"] = "getBackDir";
    getDir["curl_type"] = "getDirectories";
    CallMapMethod(clsObject, getDir);
  }
  getBackDir(data, msg){
     //alert("directory89999===="+data);

    if (data !== "0") {
      var listDir = JSON.parse(data);
      var strDir = "<div class=\"margin main_dir\">";

      for (var i = 0; i < listDir.length; i++) {
        if (listDir[i]["parent_id"] === "0" || listDir[i]["parent_id"]===0) {
          
          strDir += "<select id=\"sel"+listDir[i]["id"]+"\""+" name=\"sel"+listDir[i]["id"]+"\""+"class=\"select_target\">"+
          "<option value=\"0\">-select-</option>"+"<option value=\""+listDir[i]["id"]+"\" class=\"optionChild\">"+listDir[i]["col1"]+"</option>";
          const childFltr = d => d.parent_id === listDir[i]["id"];
          var childMenuList = listDir.filter(childFltr);
          
          for (var j = 0; j < childMenuList.length; j++) {
            strDir+="<option style=\"padding-left:15px;\" class=\"optionChild\" value=\"" + childMenuList[j]["id"] + "\">--" + childMenuList[j]["col1"] + "</option>";
            //strDir += "<a class=\"dropdown-item\" href=\"#\">" + childMenuList[j]["col1"] + "</a>";
          }
        
          strDir += "</select>";
        }

      }
      strDir += "</div>";
      $("#new_catmap").html(strDir);
      var strDirDropDown = this.getDirList(listDir);
      //alert(strDirDropDown);
      $("#parent_id").html(strDirDropDown);
      $("#folder_parent_id").html(strDirDropDown);
  }
}
getDirList(listDir) {
  
  var strDir = "<option value=\"0\" >-Select Dir-</option>";
  for (var i = 0; i < listDir.length; i++) {
    if (listDir[i]["parent_id"] === "0" || listDir[i]["parent_id"]===0) {
      strDir += "<option class=\"optionGroup\" value=\"" + listDir[i]["id"] + "\">" + listDir[i]["col1"] + "</option>";
      const childFltr = d => d.parent_id === listDir[i]["id"];
      var childMenuList = listDir.filter(childFltr);
      for (var j = 0; j < childMenuList.length; j++) {
        strDir += "<option style=\"padding-left:15px;\" class=\"optionChild\" value=\"" + childMenuList[j]["id"] + "\">--" + childMenuList[j]["col1"] + "</option>";
      }
    }

  }
  
  return strDir;
}

    savePost() {
        GetImgCertUser(clsObject, "#frmPostCall", "submitCallList", "okPost");
      }
      okPost(data, msg) {
      // alert(data);
        $("#frmPostCall").find("input[type=text]").val("");
        $("#details").val("");
        window.location.reload();
        //alert(msg);
      }
    closeMe(clName) {
        $(clName).removeClass("show");
        $(clName).removeAttr("aria-modal");
        $(clName).removeAttr("role");
        $(clName).attr("area-hidden","true");
        $(clName).hide();
        $(".modal-backdrop").remove();
      }
      showMe(clName) {
        $(clName).show();
        $(clName).addClass("show");
      }
    componentDidMount(){
        clsObject=this;
       this.getDirectories();

       // To Add New Category
       

    $(".createDirect").keypress(function (event) {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == "13") {
        //alert("I am in enter");
        var cDir = [];
        var dirParentId = 0;
        //alert($("#parent_id").val());
        if ($("#folder_parent_id").val() !== null) {
          if ($("#folder_parent_id").val() !== "0") {
            // alert("ia m in condition");
            dirParentId = $("#folder_parent_id").val();
          }
        }
        cDir.push({ name: "curl_type", value: "createDirectory" });
        cDir.push({ name: "res_func", value: "getBackDir" });
        cDir.push({ name: "dir_name", value: $(this).val() });
        cDir.push({ name: "parent_id", value: dirParentId });
        cDir.push({ name: "type_id", value: "21" });
        cDir["res_func"] = "getBackDir";
        cDir["curl_type"] = "createDirectory";
        CallMapMethod(clsObject, cDir);
      }

    });

    }
 render(){
     return(<div className="modal fade" id="modal-lg">
     <div className="modal-dialog">
     <form id="frmPostCall" enctype="multipart/form-data" method="POST" action="#">
     <input type="hidden" name="curl_type" id="curl_type" value="submitCallList" />
        <input type="hidden" name="m_type" id="m_type" value="submitCallList" />
        <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
       <div className="modal-content">
       <div className="modal fade" id="modal-folder">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header custom-modal-header">
                        <h5 className="modal-title white_c">New Folder <a className="close" onClick={() => this.closeMe("#modal-folder")} aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </a></h5>
                      </div>
                      <div className="modal-body">
                        <div className="form-group">
                          <select id="folder_parent_id" name="folder_parent_id" className="form-control">
                            <option value="0">Select Directory</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <input className="input-group input-group-sm form-control createDirect" type="text" name="newDirectory" id="newDirectory" />
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
         <div className="modal-header custom-modal-header">
           <h5 className="modal-title white_c">Add New Call <a href="javascript:void(0)" className="close" onClick={() => this.closeMe("#modal-lg")} aria-label="Close">
             <span aria-hidden="true">&times;</span>
           </a></h5>
         </div>
         <div className="modal-body">
           <div className='form-group'>
           <a href="javascript:void(0)" onClick={()=>this.showMe("#modal-folder")}><i class="fa fa-plus-square-o fa-2x" aria-hidden="true"></i></a>
           <select className="form-control no-border input-group input-group-sm" id="parent_id" name="parent_id">
                      <option value="0">Select Directory</option>
                    </select>
           </div>
           <div className="form-group">
           <input type="text" autocomplete="off" className="form-control no-border" id="col1" name="col1" placeholder="Contact No" />
           </div>
           <div className="form-group">
           <textarea placeholder="Details" className="no-border form-control flex-grow-1" row="5" id="details" name="details"></textarea>
           </div>

         </div>
         <div className="card-footer">
                            <button type="button" onClick={()=>this.savePost()} className="btn btnLoader _btnSetBank btn-primary">Submit</button>
                        </div>
       </div>
      </form>
     </div>
   </div>);
 }
}
export default Add_New_Call;