import $ from 'jquery';
import React,{ useState } from 'react';
import {CallMapMethod,RespBkMthdMapp,getSearchOutPut,taskDate} from '../function_lib/common_lib';
import Header from './header';
import Post_Thumb from '../components/post_thumb';
import Footer from './footer';
import Calendar from 'react-calendar';

import Add_New_Post from '../components/add_new_post';
import Add_New_Call from '../components/add_new_call';
import 'react-calendar/dist/Calendar.css';

let clsObject;
let crDate;
class Call_List extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
      EditAddPost(data_s){
        //alert(JSON.stringify(data_s));
        clsObject.setState(state=>({stPostEdit:data_s}));
        clsObject.forceUpdateHandler();
      }
      CloseExistingPost(clsModal){
        clsObject.closeMe(clsModal);
        clsObject.setState(state=>({stPostEdit:""}));
        clsObject.forceUpdateHandler();
    }
    closeMe(clName) {
      $(clName).hide();
    }
    getPostData(refId=0,dirParentId=0){
        var map=[];
        map.push({name:"curl_type",value:"getPostData"});
        map.push({name:"res_func",value:"callBackNg"});
        //alert("testing== "+dirParentId);
        map.push({name:"type_id",value:"10"});
        //map.push({name:"ref2_id",value:dirParentId});
        map.push({name:"parent_id",value:dirParentId});
          map["res_func"]="callBackNg";
          map["curl_type"]="getPostData";
          CallMapMethod(clsObject,map);
        }
        
callBackNg(data,msg){
    // alert(data+msg);
    if(data.trim()!=="" && data.trim()!=="0"){
        var listItems=JSON.parse(data);
        this.setState(state=>({stRecentPost:JSON.parse(data),isList:true}));
        //clsObject.setState({stRecentPost:listItems,isList:true});
        //alert(JSON.stringify(this.state.stListItems));
        this.forceUpdateHandler();
        RespBkMthdMapp(clsObject,"select_target","onChangeDirSearch","");
     }
}
onChangeDirSearch(parentId){
  //alert("i am on change");
  this.getPostData(0,parentId);
 }
     
    constructor(props){
        super(props);
        this.state={ stRecentPost:"",isList:false,stPostEdit:""};
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        crDate=new Date();
    }
    componentDidMount(){
        clsObject=this;
        this.getPostData();
    }    
    render(){
        return(<div>
            <Header/>
            <div className="content-wrapper">
   <section className="content-header">
  <div className="container-fluid">
  <div className="row mb-2" id="new_catmap">
      <div className="col-lg-3 col-md-2 col-sm-3">
        <h1>Timeline</h1>
      </div>
      <div className="col-lg-3 col-md-2 col-sm-3">
  <input className="form-control _setSearchKey" type="text" placeholder="Search" aria-label="Search" />
      </div>
      <div className="col-lg-3 col-md-2 col-sm-3">
     
      <select id="serParentId" name="serParentId" resp_dest="getTestCB" className="custom-select">
        <option value="0">Select Directory</option>
      </select>
      </div>
    </div>
    <div className="row mb-2" id="new_catmap_cal">
      <div className="col-lg-12 col-md-12 col-sm-12">
      <table className="table table-bordered table-striped table-ms">
                                                            <thead>
                                                                <tr style={{backgroundColor:"#FC766AFF"}}>
                                                                    <th>S.No.</th>
                                                                    <th>Details</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="tbData">
                                                                {this.state.isList?this.state.stRecentPost.map((item,index)=>{
                                                                    return   <tr>
                                                                    <td>{index+1}</td>
                                                                    <td>{item.col1}<br></br>{item.details}
                                                                    </td>
                                                                    <td><a href={"tel:"+item.col1}><i class="fa fa-phone fa-1x" aria-hidden="true"></i></a></td>
                                                                </tr>

                                                                }):''}
                                                            </tbody>
                                                        </table>
      </div>
    
    
    </div>
  </div>
</section>
         
        </div>
        <Footer/>
        <Add_New_Call />
        </div>)
    }
}
export default Call_List;