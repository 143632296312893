import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {AjaxCallMethod,CallMapMethod,TableDatePicker} from '../function_lib/common_lib';

import Header from './header';
import Footer from './footer';

let loginUser="";
let userObject="";
class Rpt_Perfo_Reports extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
    componentDidMount(){
        if(userObject[0].type=="3"){
            var mapCat=[];
            mapCat.push({name:"curl_type",value:"getQuizAssignedList"});
            mapCat.push({name:"res_func",value:"callBackQuizList"});
            mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
            mapCat.push({name:"emp_id",value:userObject[0].id});
            mapCat["res_func"]="callBackQuizList";
            mapCat["curl_type"]="getQuizAssignedList";
            CallMapMethod(this,mapCat);
          }else if(userObject[0].type=="1"){
            var mapCat=[];
            mapCat.push({name:"curl_type",value:"getQuizAssignedList"});
            mapCat.push({name:"res_func",value:"callBackQuizList"});
            mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
            //mapCat.push({name:"emp_id",value:userObject[0].id});
            mapCat["res_func"]="callBackQuizList";
            mapCat["curl_type"]="getQuizAssignedList";
            CallMapMethod(this,mapCat);
          }else if(userObject[0].type=="2"){
            var mapCat=[];
            mapCat.push({name:"curl_type",value:"getQuizAssignedList"});
            mapCat.push({name:"res_func",value:"callBackQuizList"});
            mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
            //mapCat.push({name:"emp_id",value:userObject[0].id});
            mapCat["res_func"]="callBackQuizList";
            mapCat["curl_type"]="getQuizAssignedList";
            CallMapMethod(this,mapCat);
          }
    }
    callBackQuizList(data,msg){
        //alert(data);
        var totalQuiz=0;
        var totalPending=0;
        var totalCompleted=0;
      if(data!=="0" && data!==""){
          var assignList=JSON.parse(data);
          var strList="";
          var quizLink="";
          var quizText="";
          //alert(data);
          for(var i=0;i<assignList.length;i++){
              totalQuiz++;
           quizLink="";
           if(assignList[i]["status"]=="Pending"){
               totalPending++;
               quizText="Start Quiz";
               quizLink="/startQuiz?rf="+assignList[i]["rf_id"]+
                        "&vId="+assignList[i]["video_url"];
           }else{
               totalCompleted++;
               quizText="View Preformance";
              quizLink="/quizReport?rf="+assignList[i]["rf_id"]
           }
           strList+="<tr>"+"<td>"+(i+1)+"</td>"+
           "<td>"+assignList[i]["company_name"]+"</td>"+
           "<td>"+assignList[i]["emp_code"]+"</td>"+
           "<td>"+assignList[i]["emp_name"]+"</td>"+
           "<td>"+assignList[i]["designation"]+"</td>"+
           "<td>"+assignList[i]["category_name"]+"</td>"+
           "<td>"+assignList[i]["t_id"]+"</td>"+
           "<td>"+assignList[i]["t_quiz"]+"</td>"+
           "<td>"+assignList[i]["t_video"]+"</td>"+
           "<td>"+assignList[i]["col2"]+"</td>"+
           "<td>"+assignList[i]["n_attemp_date"]+"</td>"+
           "<td>"+assignList[i]["n_entry_date"]+"</td>"+
           "<td>"+assignList[i]["status"]+"</td>"+
           "<td><a href=\""+quizLink+"\">"+quizText+"</a></td>"+
           "</tr>";
          }
          $("#tbData").html(strList);
         /*
          $(".t_totalQuiz").html(totalQuiz);
          $(".t_pendingQuiz").html(totalPending);
          $(".t_completeQuiz").html(totalCompleted);
          */
      }
    }
    render(){
        return(<div>
            <Header/>
                    <div class="content-wrapper">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header d-flex p-0">
                                    <h3 class="card-title p-3"></h3>
                                    <ul class="nav nav-pills ml-auto p-2">
                                        <li class="nav-item"><a class="nav-link active ordStatus" href="#tab_1" ref_node="Pending" data-toggle="tab">All</a></li>
                                        <li class="nav-item"><a class="nav-link ordStatus" href="#tab_2" ref_node="Approved" data-toggle="tab">Pending</a></li>
                                        <li class="nav-item"><a class="nav-link ordStatus" href="#tab_2" ref_node="Rejected" data-toggle="tab">Completed</a></li>
                                    </ul>
                                </div>
                                <div class="card-body">
                                    <input type="hidden" name="viewTMapp_manual" id="viewTMapp_manual" value="#" />
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="tab_1">
                                            <section class="content">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        
                                                        <div class="col-md-12">
                                                            <div class="card card-primary card-outline">
                                                                <div class="card-header">
                                                                    <h3 class="card-title">
                                                                        <i class="fa fa-edit"></i>
                                                                        Quiz Perforamnce Report
                                                                    </h3>
                                                                    <div class="card-tools">
                                                                        <div class="input-group input-group-sm" style={{width: "150px"}}>
                                                                            <a href="javascript:void(0);" id="searchEvent" name="searchEvent" class="btn btn-block btn-default btn-sm">Search</a>
                                                                        </div>
                                                                    </div>
                
                                                                    <div class="card-tools">
                                                                        <div class="input-group input-group-sm" style={{width: "150px"}}>
                
                                                                            <input type="text" name="table_search" class="form-control float-right" placeholder="Search" />
                                                                        </div>
                                                                    </div>
                
                
                                                                   
                
                                                                </div>
                                                                <div class="card-body">
                                                                    <table class="table table-bordered table-striped table-ms">
                                                                        <thead>
                                                                            <tr style={{backgroundColor:"#FC766AFF"}}>
                                                                                <th></th>
                                                                                <th>Company Name</th>
                                                                                <th>Emp Code</th>
                                                                                <th>Name</th>
                                                                                <th>Designation</th>
                                                                                <th>Quiz Attempted</th>
                                                                                <th>Level</th>
                                                                                <th>Quiz TimeSpend</th>
                                                                                <th>VideoTimeSpend</th>
                                                                                <th>Score Obtained</th>
                                                                                <th>Assign Date</th>
                                                                                <th>Attempted Date</th>
                                                                                <th>Status</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="tbData">
                                                                        </tbody>
                                                                    </table>
                                                                    <div class="row" id="tbData1">
                
                
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                </div>);
    }
}
export default Rpt_Perfo_Reports