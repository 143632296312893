import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleLogin,GoogleLogout } from 'react-google-login';
import GoogleDrive from './GoogleDrive';

import Header from './header';
import Side_Bar from './side_bar';
import Footer from './footer';
import './g_cloud.css';
//import 'https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css';
//  let GoogleDrive;
let clsObject;

var API_KEY="AIzaSyBCiVYjqqaD7HQuaYGsErUPUl10SCk5R_k";
var CLIENT_ID='245793980397-imr6dml3jnnejn9du8mmie94c96spb2r.apps.googleusercontent.com'
var AUTH_TOKEN;

function loadYoutubeApi() {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/api.js";
    script.async = true;
    script.onload = () => {
        handleClientLoad();
    };
    document.body.appendChild(script);
  }
const responseGoogle = (response) => {
    console.log(response);
  }
  const logout=()=>{
  //  console.log(response);
  }


function handleClientLoad() {
    // Loads the client library and the auth2 library together for efficiency.
    // Loading the auth2 library is optional here since `window.gapi.client.init` function will load
    // it if not already loaded. Loading it upfront can save one network request.
    window.gapi.load('client:auth2', initClient);
  }

  function initClient() {
    // Initialize the client with API key and People API, and initialize OAuth with an
    // OAuth 2.0 client ID and scopes (space delimited string) to request access.
    window.gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
        clientId: CLIENT_ID,
        scope: 'profile https://www.googleapis.com/auth/drive'
    }).then(function () {
      // Listen for sign-in state changes.
      window.gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

      // Handle the initial sign-in state.
      updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
    });
  }

  function updateSigninStatus(isSignedIn) {
     // alert("I am in Signature Update");
    // When signin status changes, this function is called.
    // If the signin status is changed to signedIn, we make an API call.
    if (isSignedIn) {
      var user=(window.gapi.auth2.getAuthInstance().currentUser.get())
      var profile = user.getBasicProfile()
      var auth=(user.getAuthResponse())
      
      AUTH_TOKEN=auth.access_token;
      localStorage.setItem('authChk',AUTH_TOKEN);
      //alert(AUTH_TOKEN);
      //console.log(profile.getImageUrl())
      var img=document.createElement("img");
      img.setAttribute("height",30);
      img.setAttribute("align","absmiddle");
      img.style.borderRadius='99% 99%';
      img.src=profile.getImageUrl();
      var content=document.createElement("SPAN");
      content.innerHTML=" "+profile.getName();
      var signin=document.getElementById("signin-button");
      signin.innerHTML='';
      signin.appendChild(img);
      signin.appendChild(content);
      document.getElementById("signout-button").style.display=""
      
      init();
    
    }
  }

  function handleSignInClick(event) {
    // Ideally the button should only show up after window.gapi.client.init finishes, so that this
    // handler won't be called before OAuth is initialized.
    window.gapi.auth2.getAuthInstance().signIn();
  }

  function handleSignOutClick(event) {
    window.gapi.auth2.getAuthInstance().signOut();
    document.getElementById("signin-button").innerHTML= "Sign In"
      
    AUTH_TOKEN = ""
    clsObject.reset()
    init()
  }

  function retrieveAllFiles(callback) {
      var retrievePageOfFiles = function(request, result) {
          request.execute(function(resp) {
          result = result.concat(resp.items);
          var nextPageToken = resp.nextPageToken;
          if (nextPageToken) {
              request = window.gapi.client.drive.files.list({
              'pageToken': nextPageToken
              });
              retrievePageOfFiles(request, result);
          } else {
              callback(result);
          }
          });
      }
      var initialRequest = window.gapi.client.drive.files.list();
      retrievePageOfFiles(initialRequest, []);
  }
function init(){
    clsObject.forceUpdateHandler();
}
class G_Cloud extends React.Component{

    forceUpdateHandler(){
        //alert("I am in reresne "+AUTH_TOKEN);
        this.forceUpdate();
      };
      constructor(props){
          super(props);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      }
    componentDidMount(){
       
        clsObject=this;
        loadYoutubeApi();
        //this.loadYoutubeApi();
    }
    render(){
        return(<div>
            <Header/>
            {/* <Side_Bar/> */}
            <div class="content-wrapper">
        <div class="content">
                        <input type="hidden" name="viewTMapp_manual" id="viewTMapp_manual" value="#" />
                                        <div class="row">
                                        <div className="col-md-3">
                                            <div class="card mt-1">
                                                    <div class="card-header">
                                                        <h3 class="card-title">G-Drive</h3>
    
                                                        <div class="card-tools">
                                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="card-body p-0">
                                                       {/* google Drive20 */}
                                                       <button class="waves-effect waves-light btn right gsign_in" id="signin-button" onClick={()=>handleSignInClick()}>Sign In1</button>
            <button style={{display:"none"}} class="waves-effect waves-light btn right" id="signout-button" onclick="handleSignOutClick()">Sign Out</button>
    
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                               <div class="card-body1 gfiles">
                                                    <GoogleDrive AUTH_TOKEN={AUTH_TOKEN}/>
                                                    </div>
        </div>
    </div>
    <Footer/>
    <div className="modal fade" id="divQuizCategory" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Create New</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreateCate">
      <input type="hidden" name="curl_type" id="curl_type" value="categoryMaster" />  
                <input type="hidden" name="m_type" id="m_type" value="categoryMaster" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
                <input type="hidden" name="gb_token" id="gb_token" value="0"/>
      <div className="modal-body">
          
      <div className="row">
      <div className="col-12">
		  <div className="form-group">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Directory/File Name</label>
            <input type="text" className="form-control" id="category_name" name="category_name" />
          </div>          
         </div>
         <div className="col-12">
		  <div className="form-group">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Description</label>
            <input type="text" className="form-control" id="category_code" name="category_code" />
          </div>          
         </div>
         <div className="col-12">
		  <div className="form-group">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Description</label>
            <input type="text" className="form-control" id="description" name="description" />
          </div>          
         </div>
         <div className="col-12">
         <div class="custom-file">
  <input type="file" class="custom-file-input" multiple id="customFile" />
  <label class="custom-file-label" for="customFile">Choose file</label>
</div>         
         </div>
      </div>
      
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>
        </div>)
    }
}
export default G_Cloud;