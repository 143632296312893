import $, { post } from 'jquery';
import React from 'react';
import './post_thumb.css';
let postData;
let checkAllow;
class Post_Thumb extends React.Component{
  componentWillReceiveProps(props){
    //alert("I am in alert");
    postData=props.stRecentPost;
    //this.state={ stPostData:props.stRecentPost,isAllow:checkAllow };
  }
    constructor(props){
        super(props);
        checkAllow=false;
        if(props.stRecentPost!==""){
            checkAllow=true;
        }
        this.state={stPostData:props.stRecentPost,isAllow:checkAllow};
        postData=props.stRecentPost;
        //postData=props.stRecentPost;
        //alert("inside=="+JSON.stringify(postData));
    }
    render(){
        return(<div id="desk_thumb" className="row">{
            (this.state.isAllow) ? postData.map((item,index)=>{
              //var dateTime=Date.parse(item.entry_date);
              //var strGetDate=dateTime.getDate()+"/"+(dateTime.getMonth()+1);
                return <div className="child_desk_thumb col-md-3" onClick={()=>this.props.callBackMethod(item)}> 
        <div className="timeline-item card card-outline card-primary">
          {/* <a href={"/post?rfid="+item.id}> */}
          <a href="javascript:void(0)">
          <div className="card-header">
            <h6 className="card-title"><i className="fa fa-clock-o"></i>{item.entry_date}</h6>
            <span className="time"><i className="fa fa-clock"></i></span>
            <div className="card-tools">
            {item.category}
            </div>
          </div>
          <div className="card-body">
          <b>{item.col1}</b><br></br>
            {item.details}
          </div>
          </a>
        </div>
        </div> 
    }):''
}
    </div>);
    }
}
export default Post_Thumb;