import $ from 'jquery';
import React from 'react';
import './footer.css';
import './footer_test.css';

class Footer extends React.Component {
  render() {
    return (
      <div>
        <a data-toggle="modal" data-target="#modal-lg" className="chat" href="javascript:void(0)">
          <i className="fa fa-plus" style={{ fontSize: "1.5rem" }} aria-hidden="true"></i>
        </a>
        <footer className="main-footer">

          <div className="float-right d-none d-sm-inline">
            Anything you want
          </div>

          <strong>Copyright &copy; MyDream <a href="#"></a>.</strong> All rights reserved.
        </footer>

        
      </div>
    );
  }
}

export default Footer;