import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {AjaxCallMethod,CallMapMethod,TableDatePicker} from '../function_lib/common_lib';

import Header from './header';
import Footer from './footer';

let loginUser="";
let userObject="";
class Emp_Analytics_Avg extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"quizPerformAnalytics"});
        mapCat.push({name:"res_func",value:"callBackQuizList"});
        mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
        mapCat["res_func"]="callBackQuizList";
        mapCat["curl_type"]="quizPerformAnalytics";
        CallMapMethod(this,mapCat);
      }
      callBackQuizList(data,msg){
        if(data!=="0" && data!==""){
            var assignList=JSON.parse(data);
            var strList="";
            var quizLink="";
            var quizText="";
            //alert(data);
            for(var i=0;i<assignList.length;i++){
               
                
                 quizText="View Log";
                quizLink="/quizReport?rf="+assignList[i]["ref_id"]
             
             strList+="<tr>"+"<td>"+(i+1)+"</td>"+
             "<td>"+assignList[i]["company_name"]+"</td>"+
             "<td>"+assignList[i]["emp_code"]+"</td>"+
             "<td>"+assignList[i]["emp_name"]+"</td>"+
             "<td>"+assignList[i]["designation"]+"</td>"+
             "<td>"+assignList[i]["total"]+"</td>"+
             "<td>"+assignList[i]["pending"]+"</td>"+
             "<td>"+assignList[i]["completed"]+"</td>"+
             "<td>"+assignList[i]["avg_perf"]+"</td>"+
             "<td><a href=\""+quizLink+"\">"+quizText+"</a></td>"+
             "</tr>";
            }
            $("#tbData").html(strList);
      }
    }
      render(){
          return(<div>
            <Header/>
                    <div class="content-wrapper">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header d-flex p-0">
                                    <h3 class="card-title p-3"></h3>
                                    <ul class="nav nav-pills ml-auto p-2">
                                        <li class="nav-item"><a class="nav-link active ordStatus" href="#tab_1" ref_node="Pending" data-toggle="tab">All</a></li>
                                        <li class="nav-item"><a class="nav-link ordStatus" href="#tab_2" ref_node="Approved" data-toggle="tab">Pending</a></li>
                                        <li class="nav-item"><a class="nav-link ordStatus" href="#tab_2" ref_node="Rejected" data-toggle="tab">Completed</a></li>
                                    </ul>
                                </div>
                                <div class="card-body">
                                    <input type="hidden" name="viewTMapp_manual" id="viewTMapp_manual" value="#" />
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="tab_1">
                                            <section class="content">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        
                                                        <div class="col-md-12">
                                                            <div class="card card-primary card-outline">
                                                                <div class="card-header">
                                                                    <h3 class="card-title">
                                                                        <i class="fa fa-edit"></i>
                                                                        Quiz Perforamnce Report
                                                                    </h3>
                                                                    <div class="card-tools">
                                                                        <div class="input-group input-group-sm" style={{width: "150px"}}>
                                                                            <a href="javascript:void(0);" id="searchEvent" name="searchEvent" class="btn btn-block btn-default btn-sm">Search</a>
                                                                        </div>
                                                                    </div>
                
                                                                    <div class="card-tools">
                                                                        <div class="input-group input-group-sm" style={{width: "150px"}}>
                
                                                                            <input type="text" name="table_search" class="form-control float-right" placeholder="Search" />
                                                                        </div>
                                                                    </div>
                
                
                                                                   
                
                                                                </div>
                                                                <div class="card-body">
                                                                    <table class="table table-bordered table-striped table-ms">
                                                                        <thead>
                                                                            <tr style={{backgroundColor:"#FC766AFF"}}>
                                                                                <th></th>
                                                                                <th>Company Name</th>
                                                                                <th>Emp Code</th>
                                                                                <th>Name</th>
                                                                                <th>Designation</th>
                                                                                <th>Total Assigned</th>
                                                                                <th>Pending</th>
                                                                                <th>Completed</th>
                                                                                <th>Avg Score</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody id="tbData">
                                                                        </tbody>
                                                                    </table>
                                                                    <div class="row" id="tbData1">
                
                
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                </div>)
      }
}
export default Emp_Analytics_Avg