import $, { post } from 'jquery';
import React from 'react';
import {DateTimePicker,TableDatePicker,CallMapMethodRef,CallMapMethod, GetImgCertUser, RespBkMthdMapp, AjaxCallMethod,dataURLtoFile,shareMultiOptions } from '../function_lib/common_lib';
import { ReactMic } from 'react-mic';
import MicRecorder from 'mic-recorder-to-mp3';
import AudioGIF from '../assets/images/audio_dem.gif';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './add_new_post.css';
import '../assets/css/magnific-popup.css';
import '../assets/js/jquery.magnific-popup.js';
//Component
import Drag_Drop_List from '../components/drag_drop_list';
import SortableList from '../components/SortableList';
import Add_Reminder from './add_reminder';
import  Sortpage from '../components/sortable';
import Socket_Lib from '../function_lib/socket_list';
//import List from '../components/List_Sort_New';
//import 'https://code.jquery.com/ui/1.9.1/jquery-ui.js';

let clsObject;
let arrCreateFiles;
let Mp3Recorder;

let objImages = [];
let objAudio = [];

let arrExistingFiles=[];
let indexExisting=0;

let isSortableIndex=0;

let filesObj=[];
class Add_New_Post extends React.Component{
 
  setDateReminderClick(){
    let reqFrmPick= $("#req_from_picker").val();
    $("#req_from").val(reqFrmPick);
    this.closeMe("#modal_add_reminder");
  }
  displaySortable(){
    if(isSortableIndex==0){
      document.getElementById("sortable-div").style.display="none";
      isSortableIndex=1;
    }else{
      document.getElementById("sortable-div").style.display="block";
      isSortableIndex=0;
    }
  }

  forceUpdateHandler(){
    this.forceUpdate();
  };
    /* Logic to Search Post Data */
  getPostDataFooter(refId=0,dirParentId=0){
    //alert("i am in post data");
    var map=[];
    map.push({name:"curl_type",value:"getPostData"});
    map.push({name:"res_func",value:"callBackNg"});
    if(dirParentId!=0){
      map.push({name:"parent_id",value:dirParentId});
    }
        map.push({name:"rfid",value:refId});
      map["res_func"]="callBackNg";
      map["curl_type"]="getPostData";
     // alert(clsObject);
      CallMapMethod(clsObject,map);
    }


  ImageThumbBack(flImg, flName,fObject,othInfo="") {
    //alert(flImg);
   
   //alert(othInfo);
    if(othInfo===""){
      othInfo="nm"+indexExisting+","+indexExisting;
    }
    indexExisting++;
    var arrNmValue=othInfo.split(',');
    //arrExistingFiles.push
    arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
    var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
    "<i class=\"fa fa-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
      "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
      "</div>";
      if(flImg.indexOf("data:video/webm")!=-1 || flImg.indexOf("data:video/mp4")!=-1){
       strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
        "<i class=\"fa fa-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
          "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
          "</div>";
      }else if(flImg.indexOf("data:application/pdf")!==-1){
        //alert(flName);
        strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
        "<i class=\"fa fa-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
          "<a target=\"blank\" onClikc=\"window.open("+flImg+")\" href=\"javascript:void(0)\">"+flName+"</a>" +
          "</div>";
      }else if(flImg.indexOf("data:application/vnd")!==-1){
        //alert("i m in set");
        strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
        "<i class=\"fa fa-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
          "<a target=\"blank\" onClikc=\"window.open("+flImg+")\" href=\"javascript:void(0)\">"+flName+"</a>" +
          "</div>";
      }
      
    //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
    objImages.push({ name: arrNmValue[1],fname:arrNmValue[0] ,value: flImg });
    $("#lsImages").val("{\"objs\":" + JSON.stringify(objImages) + "}");
    //alert(strSet);            
    $(".imgLibThumb").append(strSet);
    if(fObject!==null){
      fObject.val('');
    }
    $('.gallery-item').magnificPopup({
      type: 'image',
      gallery: {
          enabled: true
      }
  });
  RespBkMthdMapp(clsObject, "_removeBtn"+indexExisting, "rmFileProc", "2");
    //imgLibThumb
  }
  
  setAudioDiv(blobUrl, base64URL,othInfo="") {
    if(othInfo===""){
      othInfo="nm"+indexExisting+","+indexExisting;
    }
    indexExisting++;
    var arrNmValue=othInfo.split(',');
    arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
    var strAudio = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"audio_thumb_parent\"><i class=\"fa fa-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i><audio class=\"submit_audio\" id=\"audio1dd\" src=" + base64URL + " controls=\"controls\" /></div>";
    objImages.push({ name: arrNmValue[1],fname:arrNmValue[0] ,value: base64URL });
    $("#lsImages").val("{\"objs\":" + JSON.stringify(objImages) + "}");
    $(".audioLibThumb").append(strAudio);
    RespBkMthdMapp(clsObject, "_removeBtn"+indexExisting, "rmFileProc", "2");
  }
  rmFileProc(rf){
    //alert(rf);
    var cFile=[];
    cFile.push({name:"curl_type",value:"deleteFiles"});
    cFile.push({name:"res_func",value:"rmDelFile"});
    cFile.push({name:"ref",value:rf});
    // cFile.push({name:"ref_name",value:arrCreateFiles[rf]});
    cFile.push({name:"type_id",value:"5"});
    cFile["res_func"]="rmDelFile";
    cFile["curl_type"]="deleteFiles";
    CallMapMethod(clsObject,cFile);
  }
  rmDelFile(data,msg){
    //alert("after Delete"+data);
    //if(data!=="0")
    {
      if(document.getElementById("ref_div_"+data)!=null){
        document.getElementById("ref_div_"+data).remove();
      }
      
      this.lcRemoveJSON(data);
      // delete arrCreateFiles[data];
      // var strFile=clsObject.getCreatedFilesArr();
      // $("#list_files").html(strFile);
      // RespBkMthdMapp(clsObject,"_removeBtn","rmFileProc","2");
      // RespBkMthdMapp(clsObject,"_setbackparamFile","vidCBackProc");
    }
  }

  /* Loc */
  lcRemoveJSON(refKey){
    /*
    var indexI=0;
    var chkIndexExist= JSON.stringify(arrExistingFiles);
 
    for (var key in arrExistingFiles) {
      if(refKey==key){
        chkIndexExist.splice(indexI,1);
        break;
      }
    
      indexI++;
  }
  */
  var jsonP=JSON.parse($("#lsImages").val());
  var arrJson=jsonP.objs;
  //alert(arrJson.length);
  //delete arrJson[indexI];
  for(var ai=0;ai<arrJson.length;ai++){
    if(arrJson[ai]["name"]===refKey){
      arrJson.splice(ai,1);
      //arrJson.remove(indexI);
      $("#lsImages").val(JSON.stringify(jsonP));
      //alert(JSON.stringify(jsonP));
      break;
    }
  }
  
   //jsonP=JSON.parse($("#lsImages").val());
   //arrJson=jsonP.objs;
   
}
  
  componentWillReceiveProps(props){
    //alert("i m pr0"+JSON.stringify(props));
    this.props=props;
    this.setExistingPost(props.stEditData);
  }

  deletePost(){
    if(typeof this.props.stEditData.id!=="undefined"){
      if(window.confirm("Do you want to Delete "+$("#col1").val())===true){
 //alert("i m in delete");
 var getDeleted = [];
 getDeleted.push({ name: "curl_type", value: "setDeleted" });
 getDeleted.push({ name: "res_func", value: "getBackDele" });
 getDeleted.push({name:"ref_id",value:this.props.stEditData.id});
 getDeleted.push({ name: "type_id", value: "2" });
 getDeleted["res_func"] = "getBackDele";
 getDeleted["curl_type"] = "setDeleted";
 CallMapMethod(clsObject, getDeleted);
      }
     
    }
  }
  getBackDele(data,msg){
    //alert(data);
    if(data==="001"){
       window.location.reload();
    }
  }

  /* Set Existing Data to Form */
  vidCBack(ref,refIds){
  if(ref===null){
    ref="";
  }
  if(refIds===null){
    refIds="";
  }
   var arrNames=ref.split(',');
   var arrIds=refIds.split(',');
    for(var i=0;i<arrNames.length;i++){
      
      var refNmValue=arrNames[i]+","+arrIds[i];
     var vidNm= arrNames[i];
    
     //  vidNm.toUpperCase().indexOf(".MP4")!=-1 outcondi
    if(vidNm.toUpperCase().indexOf(".JPG")!=-1 || 
    vidNm.toUpperCase().indexOf(".PNG")!=-1 ||
    vidNm.toUpperCase().indexOf(".JPEG")!=-1 ||
    vidNm.toUpperCase().indexOf(".ICO")!=-1 || 
    vidNm.toUpperCase().indexOf(".MP4")!=-1 ||
    vidNm.toUpperCase().indexOf(".GIF")!=-1 || 
    vidNm.toUpperCase().indexOf(".MP3")!=-1 ||
    vidNm.toUpperCase().indexOf(".PDF")!=-1 ||
    vidNm.toUpperCase().indexOf(".XLSX")!=-1 ||
    vidNm.toUpperCase().indexOf(".DOCX")!=-1

   ){
    //alert(vidNm);
     var map2=[];
     map2.push({name:"curl_type",value:"getFilePath"});
     map2.push({name:"res_func",value:"callbkFl"});
     map2.push({name:"f_name",value:vidNm});
     map2["res_func"]="callbkFl";
     map2["curl_type"]="getFilePath";
    
     CallMapMethodRef(clsObject,map2,vidNm,refNmValue);
    }
    }
   }
   callbkFl(data,msg,obj,othInfo){
    //alert("callback fl== "+data);
    var jsF=JSON.parse(data);
    if(data.indexOf("data:image/png")!=-1){
     // alert("i am in image");
      this.ImageThumbBack(jsF.file_path,"",null,othInfo);

    }else if(data.indexOf("data:audio/mp3")!=-1){
       this.setAudioDiv(jsF.file_path,jsF.file_path,othInfo);
    }else if(data.indexOf("data:video/mp4")!=-1) {
      this.ImageThumbBack(jsF.file_path,"",null,othInfo);
    }else if(data.indexOf("data:application/pdf")!=-1){
      this.ImageThumbBack(jsF.file_path,obj,null,othInfo);
    }else if(data.indexOf("data:application/vnd")!=-1){
      //alert("i m in data");
      this.ImageThumbBack(jsF.file_path,obj,null,othInfo);
    }
    /*
    var param2=obj.getAttribute("param2");
    if(param2){
      //alert("i am in condition");
      $("#"+param2).attr("href",jsF.file_path);
    }
    obj.src=jsF.file_path;
    */
  }
  setExistingPost(data_s){
    if(data_s.length!=0){
      $("#parent_id").val("0");
      $("#ref_postid").val("0");
      $("#col1").val("");
      $("#details").val("");
      //$("#audios").val("");
      $("#lsImages").val("");
      $(".imgLibThumb").html('');
      $(".audioLibThumb").html('');
      //alert(JSON.stringify(data_s));
      $("#col1").val(data_s["col1"]);
      $("#details").val(data_s["details"]);
      $("#col4").val(data_s["col4"]);
      $("#parent_id").val(data_s["parent_id"]);
      $("#ref_postid").val(data_s["id"]);
      $("#req_from").val(data_s["req_from"]);

      /* Set Details Height */
     
     
      this.setState(state=>({stCheckList:data_s["col4"]}),()=>{
        let elemeDet=document.getElementById("details");
        elemeDet.style.height=(25+elemeDet.scrollHeight)+"px";
        //alert($("#details").val()+" "+elemeDet.scrollHeight);
      });
      this.forceUpdateHandler();
      //alert(JSON.stringify(data_s));
      //alert(data_s["file_name"],data_s["file_ids"]);
      this.vidCBack(data_s["file_name"],data_s["file_ids"]);
      this.showMe("#modal-lg");
    }else{
      //set reset event here;
      $("#parent_id").val("0");
      $("#ref_postid").val("0");
      $("#col1").val("");
      $("#details").val("");
      $("#col4").val("");
      $("#req_from").val("");
      //$("#audios").val("");
      $("#lsImages").val("");
      $(".imgLibThumb").html('');
      $(".audioLibThumb").html('');
      objImages=[];
      objAudio=[];
      this.setState(state=>({stCheckList:""}));
      this.forceUpdateHandler();
    }
   
  }

  constructor(props) {
    super(props);
    this.objSocket=new Socket_Lib();
    //this.objSocket.isRun=true;
    this.state={ stCheckList:""};
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);

    Mp3Recorder = new MicRecorder({ bitRate: 128 });
    this.state = {
      record: false,
      isRecording: false,
      blobURL: '',
      isBlocked: false
    }
   
  }
  convertDataURIToBinary(dataURI) {
    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
 

  start = () => {
    //alert("alert i m in modl");
    clsObject.showMe("#modal-audio");
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true });
        }).catch((e) => console.error(e));
    }
  };

  stop = () => {
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        this.setState({ blobURL, isRecording: false });
        //clsObject.setAudioDiv(blobURL);
        //clsObject.closeMe("#modal-audio");         
        //alert(blob);
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var base64data = reader.result;
          clsObject.setAudioDiv(blobURL, base64data);
          clsObject.closeMe("#modal-audio");
          //console.log(base64data);
        }
        //var reader = new FileReader();
        //alert(blob);
      }).catch((e) => console.log(e));
  };

  onData(recordedBlob) {
    console.log('chunk of real-time data is: ', recordedBlob);
  }

  onStop(recordedBlob) {
    console.log('recordedBlob is: ', recordedBlob);
    // let blob = await fetch(url).then(r => r.blob());
  }
  enableAudioRecord() {
    var getDir = [];
    getDir.push({ name: "curl_type", value: "getTest" });
    getDir.push({ name: "res_func", value: "getBackAudi" });
    getDir.push({ name: "dir_name", value: "" });
    getDir.push({ name: "type_id", value: "2" });
    getDir["res_func"] = "getBackAudi";
    getDir["curl_type"] = "getTest";
    CallMapMethod(clsObject, getDir);

  }
  getBackAudi(data, msg) {
    //alert(data);
  }
  getDirectories() {
    
    var getDir = [];
    getDir.push({ name: "curl_type", value: "getDirectories" });
    getDir.push({ name: "res_func", value: "getBackDir" });
    getDir.push({ name: "dir_name", value: "" });
    getDir.push({ name: "type_id", value: "2" });
    getDir["res_func"] = "getBackDir";
    getDir["curl_type"] = "getDirectories";
    CallMapMethod(clsObject, getDir);
  }
  getBackFile(data, msg) {
    //alert(data);
    if (data !== "0" && data !== "") {
      var resFile = JSON.parse(data);
      for (var i = 0; i < resFile.length; i++) {
        arrCreateFiles[resFile[i]["id"]] = resFile[i]["col1"];
        var strFile = clsObject.getCreatedFilesArr();
        $("#list_files").html(strFile);
        RespBkMthdMapp(clsObject, "_removeBtn", "rmFileProc", "2");
        RespBkMthdMapp(clsObject, "_setbackparamFile", "vidCBackProc");

      }
    }

  }
  getDirList(listDir) {
    
    var strDir = "<option value=\"0\" >-Select Dir-</option>";
    for (var i = 0; i < listDir.length; i++) {
      if (listDir[i]["parent_id"] === "0" || listDir[i]["parent_id"]===0) {
        strDir += "<option class=\"optionGroup\" value=\"" + listDir[i]["id"] + "\">" + listDir[i]["col1"] + "</option>";
        const childFltr = d => d.parent_id === listDir[i]["id"];
        var childMenuList = listDir.filter(childFltr);
        for (var j = 0; j < childMenuList.length; j++) {
          strDir += "<option style=\"padding-left:15px;\" class=\"optionChild\" value=\"" + childMenuList[j]["id"] + "\">--" + childMenuList[j]["col1"] + "</option>";
        }
      }

    }
    return strDir;
  }
  getBackDir(data, msg) {
   // alert("check === "+data);
    if (data !== "0") {
      var listDir = JSON.parse(data);
      var strDir = "<div class=\"margin main_dir\">";
    
      for (var i = 0; i < listDir.length; i++) {
        if (listDir[i]["parent_id"] === 0 || listDir[i]["parent_id"]==="0") {
         
          strDir += "<select id=\"sel"+listDir[i]["id"]+"\""+" name=\"sel"+listDir[i]["id"]+"\""+"class=\"select_target\"><option value=\""+listDir[i]["id"]+"\" class=\"optionChild\">"+listDir[i]["col1"]+"</option>";
          const childFltr = d => d.parent_id === listDir[i]["id"];
          var childMenuList = listDir.filter(childFltr);
        
          for (var j = 0; j < childMenuList.length; j++) {
            strDir+="<option style=\"padding-left:15px;\" class=\"optionChild\" value=\"" + childMenuList[j]["id"] + "\">--" + childMenuList[j]["col1"] + "</option>";
            //strDir += "<a class=\"dropdown-item\" href=\"#\">" + childMenuList[j]["col1"] + "</a>";
          }
        
          strDir += "</select>";
        }

      }
      strDir += "</div>";
     
      $("#new_catmap").html(strDir);
      var strDirDropDown = this.getDirList(listDir);
      //alert(strDirDropDown);
      $("#folder_parent_id").html(strDirDropDown);
      $("#parent_id").html(strDirDropDown);
      //$("#folder_parent_id").html(strDir);
      //$("#parent_id").html(strDir);
      
      $("#serParentId").html(strDir);
      //$("#newDirectory").css("display","none");
      $("#newDirectory").val("");
      $("#folder_parent_id").val("0");
      $(".resCount").html("");
    }
  }
  
  getBackDir_1(data, msg) {
    // alert(data);
    if (data !== "0") {
      var listDir = JSON.parse(data);
      var strDir = "<option value=\"0\">-Select Dir-</option>";
      for (var i = 0; i < listDir.length; i++) {
        if (listDir[i]["parent_id"] === "0") {
          strDir += "<option class=\"optionGroup\" value=\"" + listDir[i]["id"] + "\">" + listDir[i]["col1"] + "</option>";
          const childFltr = d => d.parent_id === listDir[i]["id"];
          var childMenuList = listDir.filter(childFltr);
          for (var j = 0; j < childMenuList.length; j++) {
            strDir += "<option style=\"padding-left:15px;\" class=\"optionChild\" value=\"" + childMenuList[j]["id"] + "\">--" + childMenuList[j]["col1"] + "</option>";
          }
        }

      }
      var strDirDropDown = this.getDirList(listDir);
      $("#folder_parent_id").html(strDirDropDown);
      $("#parent_id").html(strDirDropDown);
      $("#serParentId").html(strDir);
      //$("#newDirectory").css("display","none");
      $("#newDirectory").val("");
      $("#folder_parent_id").val("0");
      $(".resCount").html("");

    }
  }
  closeMe(clName) {
    $(clName).hide();
  }
  showMe(clName) {
    $(clName).show();
    $(clName).addClass("show");
  }
  displayCreateDir(contName) {
    $(contName).css("display", "block");
  }
  savePost() {
    $('#sortable-div .sortable-list').each(function() {
      var result = "";
          var valu="";
       $(this).find("li").each(function(){
           result += $(this).attr('data-id') + ",";
       });
           $(this).find("[type=text]").each(function(){
             if(valu!==""){
               valu+=":";
             }
                valu+=$(this).val();
           });
       //alert("value=="+valu);
           $("#col4").val(valu);
       //$("."+$(this).attr("id")+".list").html(result);
   });
    //alert("i am in save");
    GetImgCertUser(clsObject, "#frmPost", "submitPost", "okPost");
  }
  okPost(data, msg) {
    //alert(msg);
    $("#frmPost").find("input[type=text]").val("");
    $(".imgLibThumb").html("");
    $(".audioLibThumb").html("");
    $("#lsImages").val("");
    //$("#audios").val("");
    $("#details").val("");
    window.location.reload();
    //alert(msg);
  }
  componentDidMount() {
    arrCreateFiles={};
    arrExistingFiles={};
    clsObject = this;
    this.displaySortable();
    $("#details").on('input', function() {
      var scroll_height = $("#details").get(0).scrollHeight;
      $("#details").css('height', scroll_height + 'px');
    });

    navigator.getUserMedia({ audio: true },
      () => {
        console.log('Permission Granted');
        this.setState({ isBlocked: false });
      },
      () => {
        console.log('Permission Denied');
        this.setState({ isBlocked: true })
      },
    );

    this.getDirectories();
    /*Process for New File Creation */
    $(".createFile").keypress(function (event) {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == "13") {
        var cFile = [];
        var fileType = "0";

        if ($("#file_type").val() !== null) {
          if ($("#file_type").val() !== "0") {
            fileType = $("#file_type").val();
          }
        }
        //alert("i am in enter of file creation");
        cFile.push({ name: "curl_type", value: "createFile" });
        cFile.push({ name: "res_func", value: "getBackFile" });
        cFile.push({ name: "file_name", value: $(this).val() });
        cFile.push({ name: "file_type", value: fileType });
        cFile.push({ name: "type_id", value: "5" });
        cFile["res_func"] = "getBackFile";
        cFile["curl_type"] = "createFile";
        CallMapMethod(clsObject, cFile);
        //clsObject.getBackFile("micrsco1.docx");
      }
    });


    $(".createDirect").keypress(function (event) {
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == "13") {
        //alert("I am in enter");
        var cDir = [];
        var dirParentId = 0;
        //alert($("#parent_id").val());
        if ($("#folder_parent_id").val() !== null) {
          if ($("#folder_parent_id").val() !== "0") {
            // alert("ia m in condition");
            dirParentId = $("#folder_parent_id").val();
          }
        }
        cDir.push({ name: "curl_type", value: "createDirectory" });
        cDir.push({ name: "res_func", value: "getBackDir" });
        cDir.push({ name: "dir_name", value: $(this).val() });
        cDir.push({ name: "parent_id", value: dirParentId });
        cDir.push({ name: "type_id", value: "2" });
        cDir["res_func"] = "getBackDir";
        cDir["curl_type"] = "createDirectory";
        CallMapMethod(clsObject, cDir);
      }

    });


    // RespBkMthdMapp(clsObject,"_setSearchKey","keySearch");
    AjaxCallMethod(clsObject, "okSuccNot");
    //this.showMe("#modal-folder");
  }

  shareAll(){
    //alert("I m in Share All Option");
    filesObj=[];
    let strLsImages=$("#lsImages").val();
    let objLsImages=JSON.parse(strLsImages);
    //alert(objLsImages.objs.length);
    for(let i=0;i<objLsImages.objs.length;i++){
      //alert(objLsImages.objs[i].value);
      let file= dataURLtoFile(objLsImages.objs[i].value,objLsImages.objs[i].fname);
      filesObj.push(file);
    }
    let titleTxt=$("#col1").val();
    let descTxt=$("#details").val();
    //alert(descTxt);
    shareMultiOptions(filesObj,titleTxt,descTxt);
  }
    render(){
        return(<div id="modal-lg" className="modal fade mod_new" >
        <div className="modal-dialog modal_new">
          <div className="modal-content modc">
            <form id="frmPost" enctype="multipart/form-data" method="POST" action="#">
              <div className="modal-header ash-header">
                <div className="row ash-b-bottom">
                  <div className="col-md-6 col-8">
                  <ul className="nav nav-pills ml-auto p-2">
                    <li className="nav-item dropdown">
<a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#">
<i class="fa fa-ellipsis-v" aria-hidden="true"></i> <span className="caret"></span>
</a>
<div className="dropdown-menu">
<a onClick={()=>this.showMe("#modal_add_reminder")} className="dropdown-item" tabindex="-1" href="#"><i class="fa fa-clock-o" aria-hidden="true"></i>Reminder</a>
  <a className="dropdown-item" tabindex="-1" href="#"><i class="fa fa-lock" aria-hidden="true"></i>Lock</a>
  <a className="dropdown-item" tabindex="-1"  onClick={()=>this.shareAll()} href="javascript:void(0)"><i class="fa fa-share-alt" aria-hidden="true"></i>Share</a>
  <a className="dropdown-item" tabindex="-1" onClick={()=>this.shareAll()} href="javascript:void(0)"><i class="fa fa-share-square-o"  aria-hidden="true"></i>Export</a>
  <a className="dropdown-item" tabindex="-1" onClick={()=>this.deletePost()} href="#"><i class="fa fa-trash-o" aria-hidden="true"></i>Delete</a>
  
</div>
</li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-4">
                    <a href="javascript:void(0);" onClick={()=>{this.props.callBackClose("#modal-lg")}} className="md_close" data-dismiss="modal"><i className="fa fa-close" aria-hidden="true"></i></a>
                    <button type="button" className="btn btn-primary btnLoader sbtn" onClick={() => this.savePost()}><i className="fa fa-floppy-o" aria-hidden="true"></i></button>
                   
                    {/* <a href="javascript:void(0)" className="btn btn-warning sbtn" onClick={() => this.showMe("#modal-folder")}>New</a> */}
                  </div>
                </div>
                
                <div className="row">
                    <div className="form-group col-6">
                    <TableDatePicker  refName="from_dt"/>
                    {/* <input type="date" className="form-control" /> */}
                    </div>
                    <div className="form-group col-6">
                    <div className="input-group input-group-sm">
                    <a href="javascript:void(0)" onClick={()=>this.showMe("#modal-folder")}><i class="fa fa-plus-square-o fa-2x" aria-hidden="true"></i></a>
                    <select className="form-control no-border input-group input-group-sm" id="parent_id" name="parent_id">
                      <option value="0">Select Directory</option>
                    </select>
                      
                      </div>
                    </div>
                    </div>
              </div>
              <div className="modal-body less-padding">
                <input type="hidden" name="curl_type" id="curl_type" value="submitPost" />
                <input type="hidden" name="m_type" id="m_type" value="submitPost" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
                <input type="hidden" name="c_files" id="c_files" value="" />
                <input type="hidden" name="ref_postid" id="ref_postid"/>
                <div className="container-fluid">
                  <div className="less-padding d-flex flex-column">
                 
                  <div className="resCount"></div>
                    <input type="hidden" name="lsImages" id="lsImages" />
                    {/* <Sortpage/> */}
                   
                    {/* <List data={["iaskldfsdf","asdfasdf"]}/> */}
                    {/* <SortableList items={["testin1","testing2"]}/> */}
                    <div className="flex-grow-1 d-flex flex-column">
                    <input type="text" className="form-control no-border" id="col1" name="col1" placeholder="Title" />
                    <input type="hidden" name="col4" id="col4" />
                    <Drag_Drop_List checkList={this.state.stCheckList}/>
                      <textarea placeholder="Details" className="form-control no-border flex-grow-1" id="details" name="details"></textarea>
                    </div>
                    <div className="row audioLibThumb imgLibThumb">
                      
                      </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-6">
                          
                          <p id="list_files" className="help-block"></p>
                        </div>
                      </div>

                    </div>
                 
                   

                  </div>

                </div>




              </div>
            
            <div className="modal-footer mf-footer pull-right">
              <div className="col-xs-12 col-lg-12 ">
                <div className="modal fade" id="modal-folder">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header custom-modal-header">
                        <h5 className="modal-title white_c">New Folder <a className="close" onClick={() => this.closeMe("#modal-folder")} aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </a></h5>
                      </div>
                      <div className="modal-body">
                        <div className="form-group">
                          <select id="folder_parent_id" name="folder_parent_id" className="form-control">
                            <option value="0">Select Directory</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <input className="input-group input-group-sm form-control createDirect" type="text" name="newDirectory" id="newDirectory" />
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
                <div className="modal fade"  id="modal_add_reminder">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header custom-modal-header">
                        <h5 className="modal-title white_c">Reminder <a className="close" onClick={() => this.closeMe("#modal_add_reminder")} aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </a></h5>
                      </div>
                      <div className="modal-body" style={{minHeight:"50vh"}}>
                        <div className="form-group">
                        <label for="exampleInputEmail1"></label>
                         <DateTimePicker refName="req_from_picker" />
                         <input type="hidden" name="req_from" id="req_from" />
                        </div>
                       
                      </div>
                      <div className="card-footer">
                            <button type="button" onClick={()=>this.setDateReminderClick()} className="btn _btnSetBank btn-primary">Submit</button>
                        </div>
                    </div>

                  </div>
                </div>
                <div className="modal fade" id="modal-file">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header custom-modal-header">
                        <h5 className="modal-title white_c">New File<a className="close" onClick={() => this.closeMe("#modal-file")} aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </a></h5>
                        
                      </div>
                      <div className="modal-body">
                        <div className="form-group">
                          <select id="file_type" name="file_type" className="form-control createFileGp">
                            <option value="0">-File Type-</option>
                            <option value="word">Word</option>
                            <option value="excel">Excel</option>
                            <option value="powerpoint">PowerPoint</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <input className="input-group form-control input-group-sm createFile createFileGp" type="text" name="createFile" id="createFile" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal fade" id="modal-audio">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header custom-modal-header">
                        <h5 className="modal-title white_c">Voice Record  <a className="close" onClick={() => this.closeMe("#modal-audio")} aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </a></h5>

                      
                      </div>
                      <div className="modal-body">
                        <input type="file" ref_img="ImageThumbBack" multiple name="customFileBr" id="customFileBr" className="updImgThumb" />
                        <img src={AudioGIF} alt="audiorec" width="100%" height="50px" />
                        <button onClick={this.stop} disabled={!this.state.isRecording}>
                          Stop
                        </button>


                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <a href="javascript:void(0)" disabled={this.state.isRecording} onClick={this.start} className="ml-2 pr-2">
                    <i className="fa fa-microphone fa-2x" aria-hidden="true"></i>
                  </a>
                  <a href="javascript:void(0)" setTo="customFileBr" className="ml-2 pr-2 _brFileThumb">
                    <i className="fa fa-file-image-o fa-2x" aria-hidden="true"></i>
                  </a>
                  <a href="javascript:void(0)" setTo="customFileBr" className="ml-2 pr-2 _brFileThumb">
                    <i className="fa fa-video-camera fa-2x" aria-hidden="true"></i>
                  </a>
                  <input type="file" ref_count="resCount" multiple name="customFileAttach" id="customFileAttach" className="updImg" />
                  <a href="javascript:void(0)" setTo="customFileBr" className="ml-2 pr-2 _brFile"><i className="fa fa-file-word-o fa-2x" aria-hidden="true"></i></a>
                  <a href="javascript:void(0)" setTo="customFileBr" className="ml-2 pr-2 _brFile"><i className="fa fa-file-excel-o fa-2x" aria-hidden="true"></i></a>
                  <a href="javascript:void(0)" setTo="customFileBr" className="ml-2 pr-2 _brFile"><i className="fa fa-paperclip fa-2x" aria-hidden="true"></i></a>
                  <a href="javascript:void(0)" onClick={() => this.showMe("#modal-file")} className="ml-2 pr-2"><i class="fa fa-file-o fa-2x" aria-hidden="true"></i></a>
                  {/*<a href="javascript:void(0)" onClick={()=>this.displaySortable()} className="ml-2 pr-2"><i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i></a>*/}
                </div>
              </div>
            </div>
            </form>
          </div>
         
        </div>
       
      </div>)
    }
}
export default Add_New_Post;