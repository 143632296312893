import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import UserToken from '../function_lib/userToken';
import {GetImgCertUser,RespBkMthdMapp,displayQuest,getQuestions,AjaxCallMethod,CallMapMethod,TableDatePicker} from '../function_lib/common_lib';
import Vimeo from '@u-wave/react-vimeo'

import Header from './header';
import Footer from './footer';
import  './start_quiz.css';

let loginUser="";
let userObject="";
let clsObject;

let videoId="";
let queries;
let rf;

let setVideoInter;
let vidTime=0;

let setQuizInter;
let quizTime=0;

function onVideoEnd(){
  //alert("test i am on end");
  $(".vidNext").css("display","block");
}
function onStartVim(){
  //alert("i am on start");
   $(".fullscreen").click();
}
function startQust(){
  $("#playVideo").css("display","none");
  $("#quizQuestions").css("display","block");
  clearInterval(setVideoInter);
  
  setQuizInter= setInterval(function(){
    quizTime= quizTime+1;
    $("#t_quiz").val(quizTime);
    var qTm=quizTime/60;
    qTm=qTm.toFixed(2);
    $(".qz_clock").html(qTm);
   },1000);
}
class Start_Quiz extends React.Component
{
  saveEdu(){
    GetImgCertUser(clsObject,"#qust_quiz","submitTest","okEdut");
  }
  okEdut(data,datares,userResp){
    //alert(datares);
    if(userResp!=="0"){
      //$("#getReponse").html("Uploaded Successfully");
    }
    if(data!=="0"){
    window.location.href="/quizReport?rf="+data; 
    }else{
      //$(".save_resp").html(datares);
    }
   
  }
    constructor(props){
        super(props);
        if(props !=null){
          queries = QueryString.parse(this.props.location.search);
          if(queries.rf!=null){
            rf=queries.rf;
            videoId=queries.vId;
          }
        }
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
     
      componentDidMount(){
        var tv=0;
       setVideoInter= setInterval(function(){
        vidTime++;
        $("#t_video").val(vidTime);
       },1000);

       $("#quizQuestions").css("display","none");
       $("#gb_token").val(userObject[0].app_user_id);
       $("#user_token").val(userObject[0].id);
       $("#ref_id").val(rf);
       //alert(rf);
        clsObject=this;
        var map=[];
        map.push({name:"curl_type",value:"getQuizQuest"});
        map.push({name:"res_func",value:"callBackNg"});
        map.push({name:"gb_token",value:userObject[0].app_user_id});
        map.push({name:"emp_id",value:userObject[0].id});
        map.push({name:"quiz_id",value:rf});
        map["res_func"]="callBackNg";
        map["curl_type"]="getQuizQuest";
        CallMapMethod(this,map);
        RespBkMthdMapp(clsObject,"setEventsQst","qustClick")
      }
      callBackNg(data,msg){
        //  alert(data);
        if(data!=="0" && data!==""){
           var resQust=getQuestions(data);
           $("#quest").html(resQust);
           var getCount=JSON.parse(data);
           var strCount="";
           for(var c=0;c<getCount.length;c++){
             strCount+="<div class=\"col-4\">"+
             "<a href=\"#\" param1=\""+c+"\" class=\"nav-link shadow p-3 indxClick\">"+
                 (c+1)+
                 "</a>"+              
             "</div>";
           }
           if(getCount.length!=0){
            $("#limitIndex").val(getCount.length);
            $("#qtIndex").html(strCount);
            displayQuest(0,"tabcontent");
            clsObject.checkDisplay(0);
            $("#flowIndex").val(0);
            RespBkMthdMapp(clsObject,"setEvents","indxClick");
           }
           
           
        }
      }
      
      checkDisplay(index){
        var limitIndex=$("#limitIndex").val();
        $("#btnPre").css("display","block");
        $("#btnNext").css("display","block");
        if(index==0){
          $("#btnPre").css("display","none");
        }else if(index>(limitIndex-1)){
          $("#btnNext").css("display","none");
          $("#submitQuiz").css("display","block");
        }
      }
      setEvents(refIndex){
        clsObject.checkDisplay(refIndex);
        displayQuest(refIndex,"tabcontent");
        $("#flowIndex").val(refIndex);
      }
      setEventsQst(refIndex){
         var flowIndx=$("#flowIndex").val();
         var limitIndex=$("#limitIndex").val();
         flowIndx= parseInt(flowIndx);
         refIndex=parseInt(refIndex);
         flowIndx+=refIndex;
         clsObject.checkDisplay(flowIndx);
         if(flowIndx<0){
           return;
         }else if(flowIndx>=limitIndex){
          displayQuest(-1,"tabcontent");
             return;
         }else{
          displayQuest(flowIndx,"tabcontent");
          $("#flowIndex").val(flowIndx);
         }
      }
    render(){
        return(<div>
<Header/>
<input type="hidden" name="flowIndex" id="flowIndex" />
<input type="hidden" name="limitIndex" id="limitIndex"/>
<div class="content-wrapper">
<section class="content">
<div class="tab-content">
  <div className="row" id="playVideo">
   
  <div className="col-12">
  <div style={{width:"100%",textAlign:"center"}}>
  <button type="button" onClick={()=>startQust()}  className="_btnNext vidNext" >Click here to Start Quiz</button>
</div>
  <Vimeo
  video={videoId}
  autoplay
  allowfullscreen={"allowfullscreen"}
  mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"
  style={{height:"100vh",width:"100%"}}
  onEnd={() => onVideoEnd()}
  onPlay={()=>onStartVim()}
/>
        </div>
  </div>
  </div>
  <div id="quizQuestions">
  <form id="qust_quiz" action="#" method="POST" enctype="multipart/form-data">
  <input type="hidden" name="gb_token" id="gb_token" />
  <input type="hidden" name="user_token" id="user_token" />
  <input type="hidden" name="ref_id" id="ref_id"/>
  <input type="hidden" name="t_video" id="t_video"/>
  <input type="hidden" name="t_quiz" id="t_quiz"/>

  <div className="row">

<div class="col-md-2">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title">Time Clock :<span className="qz_clock"></span></h3>
    
                                                        <div class="card-tools">
                                                           
                                                        </div>
                                                    </div>
                                                    <div class="card-body p-0">
                                                        <ul class="nav nav-pills flex-column">
                                                            <li class="nav-item">
                                                                <a href="javascript:void(0);"  setl="#" class="btn btn-block btn-default btn-sm">15:00</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title">Question Index</h3>
    
                                                        <div class="card-tools">
                                                      
                                                        </div>
                                                    </div>
                                                    <div class="card-body p-0">
                                                        <div id="qtIndex" className="row">
                                                        
                                                        </div>
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-10 col-lg-10 col-md-8 col-sm-8 bg-white shadow p-3">
<div id="quest">

      </div>
      <div style={{display:"none"}} id="submitQuiz">
      <div className="card border p-3 mt-3">
      <div className="w-100">
        I have Attempted the Quiz and Answered as per my best knowledge and experience!
      </div>
      <div className="row">
        <div className="col-6"></div>
        <div className="col-6">
        <button onClick={this.saveEdu} class="btn btn-secondary" id="btnFinalSubmit" type="button">
          Submit The Quiz</button>
        </div>
      </div>
      
  </div>
      </div>
      <div class="bottom-row-step">
        <div className="row">
        <div className="col-6">
        <button param1="-1" class="btn btn-secondary qustClick" id="btnPre" type="button">
          Previous</button>
        </div>
        <div className="col-6">
        <button param1="1" class="btn btn-primary qustClick" id="btnNext" type="button">Next</button>
        </div>
        </div>
      
          
          </div>
      </div>
      
      </div>
      </form>
  </div>

      </section>
      </div>
<Footer/>
        </div>)
    }
}
export default Start_Quiz;