import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod} from '../function_lib/common_lib';

import Header from './header';
import Side_Bar from './side_bar';
import Footer from './footer';

let loginUser="";
let userObject="";
let clsObject;
class File_Store extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){
        clsObject=this;
          $("#gb_token").val(userObject[0].app_user_id);
          AjaxCallMethod(this,"okSuccCNOT");
          clsObject.getCategoryList();
          
      }
      getCategoryList(){
        var map=[];
        map.push({name:"curl_type",value:"getCategory"});
        map.push({name:"res_func",value:"callBackNg"});
        map.push({name:"gb_token",value:userObject[0].app_user_id});
        map.push({name:"emp_id",value:userObject[0].id});
        map["res_func"]="callBackNg";
        map["curl_type"]="getCategory";
        CallMapMethod(this,map);
      }

      //Relative Methods
      callBackNg(data,msg){
         // alert(data);
        if(data!=="0" && data!==""){
            var listItems=JSON.parse(data);
            var str="";
            for(var i=0;i<listItems.length;i++){
            str+="<tr>"+
            "<td>"+(i+1)+"</td>"+
            "<td>"+listItems[i]["category_name"]+"</td>"+
            "<td>"+listItems[i]["category_code"]+"</td>"+
            "<td>"+listItems[i]["video_url"]+"</td>"+
            "<td>"+listItems[i]["status"]+"</td>"+"</tr>";
            }
            $("#tbData").html(str);
            $(".total_rec").html(listItems.length);
        }
      }
      okSuccCNOT(data,datares){
         // alert(datares);
        if(data!="0" && data!==""){
          $("#frmCreateCate").find("input[type=text]").val("");
          $("#getReponse").html("Category Created Successfully..");
          clsObject.getCategoryList();
        }else{
          $("#getReponse").html("Invalid UserName or Password !");
        }
    }

    render(){
        return(<div>
<Header/>
            {/* <Side_Bar/> */}
        <div class="content-wrapper">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <input type="hidden" name="viewTMapp_manual" id="viewTMapp_manual" value="#" />
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab_1">
                                <section class="content">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title">Add New</h3>
    
                                                        <div class="card-tools">
                                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="card-body p-0">
                                                        <ul class="nav nav-pills flex-column">
                                                            <li class="nav-item">
                                                                <a href="javascript:void(0);" data-toggle="modal" data-target="#divQuizCategory" _hHeight="60%" _hWidth="70%" setl="#" class="btn _hcolorspp btn-block btn-default btn-sm">Add New</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h3 class="card-title">Status within Duration</h3>
    
                                                        <div class="card-tools">
                                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="card-body p-0">
                                                        <ul class="nav nav-pills flex-column">
                                                            <li class="nav-item active">
                                                                <a href="#" class="nav-link">
                                                                    <i class="fa fa-building"></i> Total
                                                                    <span class="badge bg-primary float-right total_rec"></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-9">
                                                <div class="card card-primary card-outline">
                                                    <div class="card-header">
                                                        <h3 class="card-title">
                                                            <i class="fa fa-edit"></i>
                                                            File Store
                                                        </h3>
                                                        <div class="card-tools">
                                                            <div class="input-group input-group-sm" style={{width: "150px"}}>
                                                                <a href="javascript:void(0);" id="searchEvent" name="searchEvent" class="btn btn-block btn-default btn-sm">Search</a>
                                                            </div>
                                                        </div>
    
                                                        <div class="card-tools">
                                                            <div class="input-group input-group-sm" style={{width: "150px"}}>
    
                                                                <input type="text" name="table_search" class="form-control float-right" placeholder="Search" />
                                                            </div>
                                                        </div>
    
    
                                                        <div class="card-tools">
                                                            <div class="input-group input-group-sm" style={{width: "150px"}}>
                                                                <select class="form-control select_target_man" id="som" name="som">
                                                                    <option value="0">-Select-</option>
                                                                    <option value="Directory">View Directory</option>
                                                                    <option value="Files">View Files</option>
                                                                </select>
                                                            </div>
                                                        </div>
    
                                                    </div>
                                                    <div class="card-body">
                                                        <table class="table table-bordered table-striped table-ms">
                                                            <thead>
                                                                <tr style={{backgroundColor:"#FC766AFF"}}>
                                                                    <th>S.No.</th>
                                                                    <th>Directory/File Name</th>
                                                                    <th>Created Date</th>
                                                                    <th>Reminder</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="tbData">
                                                            </tbody>
                                                        </table>
                                                        <div class="row" id="tbData1">
    
    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    <div className="modal fade" id="divQuizCategory" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
    <div className="modal-header bg-primary">
        <h5 className="modal-title text-white" id="exampleModalLabel">Create New</h5>
        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className="text-white">&times;</span>
        </button>
      </div>
      <form id="frmCreateCate">
      <input type="hidden" name="curl_type" id="curl_type" value="categoryMaster" />  
                <input type="hidden" name="m_type" id="m_type" value="categoryMaster" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
                <input type="hidden" name="gb_token" id="gb_token" value="0"/>
      <div className="modal-body">
          
      <div className="row">
      <div className="col-12">
		  <div className="form-group">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Directory/File Name</label>
            <input type="text" className="form-control" id="category_name" name="category_name" />
          </div>          
         </div>
         <div className="col-12">
		  <div className="form-group">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Description</label>
            <input type="text" className="form-control" id="category_code" name="category_code" />
          </div>          
         </div>
         <div className="col-12">
		  <div className="form-group">
            <label for="recipient-name" className="col-form-label ss-font-style-3 font-sm">Description</label>
            <input type="text" className="form-control" id="description" name="description" />
          </div>          
         </div>
         <div className="col-12">
         <div class="custom-file">
  <input type="file" class="custom-file-input" multiple id="customFile" />
  <label class="custom-file-label" for="customFile">Choose file</label>
</div>         
         </div>
      </div>
      
      </div>
      <div className="modal-footer">
      <div id="getReponse" style={{color:"red"}}></div>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn _btnSetColor btn-primary">Save changes</button>
      </div>
      </form>
    </div>
    </div>
    </div>

    </div>
    )
    }
}
export default File_Store;