import $, { post } from 'jquery';
import React from 'react';
import { sortable } from 'react-sortable';
let si;
class Drag_Drop_List extends React.Component{
    constructor(props){
      super(props);
    }
    componentWillReceiveProps(props){
        //alert(JSON.stringify(props));
       // alert(props.checkList);
        if(props.checkList){
            document.getElementById("sortable-div").style.display="block";
            $("#sortlistId").html("");
            var checkList=props.checkList;
            var arrList=checkList.split(':');
            for(var cl=0;cl<arrList.length;cl++){
                this.addSortList(arrList[cl]);
            }
        }else{
            $("#sortlistId").html("");
            document.getElementById("sortable-div").style.display="none";
        }
    }
    addSortList(strVal=""){
        si++;
        var strText="<li class=\"sortable-item\" data-id=\""+si+"\">"+
                    "<label>"+
            "<span class=\"dropicon\"><i class=\"fa fa-list-ul\" aria-hidden=\"true\"></i></span>"+
            "<input type=\"checkbox\" class=\"filled-in\" /> <span><input type=\"text\" value=\""+strVal+"\" class=\"checklist_text no-border\" /><i class=\"fa fa-close\" aria-hidden=\"true\"></i></span>"+
        "</label></li>";
        $("#sortlistId").append(strText);
        
    }
    componentDidMount(){
        si=1;
        $( "#sortable-div .sortable-list" ).sortable({
		    connectWith: "#sortable-div .sortable-list",
		    stop: function(event, ui) {
		        $('#sortable-div .sortable-list').each(function() {
		           var result = "";
                   var valu="";
		            $(this).find("li").each(function(){
		                result += $(this).attr('data-id') + ",";
		            });
                    $(this).find("[type=text]").each(function(){
                         valu+=$(this).val()+":";
                    });
		            //alert("value=="+valu);
                    //$("#col5").val(valu);
		            //$("."+$(this).attr("id")+".list").html(result);
		        });
		    }
		});
    }
    render(){
        return(<div id="sortable-div" className="col-sm-12 col-xs-12 col-md-9 addSub">
        <div id="dragdrop" className="col-sm-4 col-xs-4 col-md-4">
            <div className="well clearfix">
                <div className="dragbleList">
                    <ul id="sortlistId" className="sortable-list">
                        <li className="sortable-item" data-id="1">
                            <label>
                                <span className="dropicon"><i className="fa fa-list-ul" aria-hidden="true"></i></span>
                                <input type="checkbox" className="filled-in" /> <span><input type="text" className="checklist_text no-border" /><i className="fa fa-close" aria-hidden="true"></i></span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <li className="sortable-item11" data-id="01">
                            <label>
                                <span className="dropicon"></span>
                                 <span onClick={()=>this.addSortList()}><i className="fa fa-plus" aria-hidden="true"></i>Add New</span>
                            </label>
                        </li>
    </div>)
    }
}
export default Drag_Drop_List;
