import $ from 'jquery';
import React from 'react';
import OrgChart from '@balkangraph/orgchart.js';
import { Editor } from "react-draft-wysiwyg";
import QueryString from 'query-string';
import UserToken from '../function_lib/userToken';
import {AjaxCallMethod,VideoGallery,ImageGallery,getSearchOutPut,CallMapMethodRef,CallMapMethod,drawChart,GetImgCertUser,TimeLine,RespBkMthdMapp, TimeLineVideo, TimeLineImg} from '../function_lib/common_lib';
import Add_New_Post from '../components/add_new_post';
import Header from './header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/Bhandarkarlogo.png';
import defaultPic from '../assets/images/9.png';
//import Post_Timeline from './post_timeline';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './post_timeline_new.css';
import '../assets/css/magnific-popup.css';
import '../assets/js/jquery.magnific-popup.js';



let loginUser="";
let userObject="";
let clsObject;
let queries;
let rf;
let type_rf;
let rfid;
let arrCreateFiles;
class Post_Timeline_New extends React.Component{
  closeMe(clName){
    // $(clName).modal("hide");
    $(clName).hide();
  }
  showMe(clName){
    $(clName).show();
    $(clName).addClass("show");
    // $(clName).modal("show");
  }
  displayCreateDir(contName){
    $(contName).css("display","block");
  }
  savePost(){
    GetImgCertUser(clsObject,"#frmPost","submitPost","okPost");
  }
  okPost(data,datares,userResp){
    //alert(datares);
    if(userResp!=="0"){
      $("#getReponse").html("Uploaded Successfully");
    }
    if(data!=="0"){
     
    }else{
      $(".save_resp").html(datares);
    }
   
  }

   /* Method to Handle AddNewPost Edit */
   EditAddPost(data_s){
    //alert(JSON.stringify(data_s));
    clsObject.setState(state=>({stPostEdit:data_s}));
    clsObject.forceUpdateHandler();
  }
  CloseExistingPost(clsModal){
      clsObject.closeMe(clsModal);
      clsObject.setState(state=>({stPostEdit:""}));
      clsObject.forceUpdateHandler();
  }
  forceUpdateHandler(){
    this.forceUpdate();
  };
  constructor(props){
    super(props);
    this.state={ stRecentPost:"",isList:false,stPostEdit:""};
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    if(props !=null){
      queries = QueryString.parse(this.props.location.search);
      if(queries.rf!=null){
        rf=queries.rf;
      }
      if(queries.type_rf!=null){
        type_rf=queries.type_rf;
      }
      if(queries.rfid!=null){
        rfid=queries.rfid;
      }
    }
    if(localStorage.getItem("userData")!=null){
      UserToken.setUserObject(localStorage.getItem("userData"));
      userObject= UserToken.getUserObject();
      loginUser=userObject[0].person_name;
    }
  }
  
  getPostData(refId=0){
    //alert("i am in post data");
    var map=[];
    map.push({name:"curl_type",value:"getPostData"});
    map.push({name:"res_func",value:"callBackNg"});
    var dirParentId=0;
    //alert($("#parent_id").val());
    if($("#serParentId").val()!==null){
      if($("#serParentId").val()!=="0"){
       // alert("ia m in condition");
        dirParentId=$("#serParentId").val();
      }
    }
    //alert(dirParentId);
      map.push({name:"parent_id",value:dirParentId});
      if(rfid){
        map.push({name:"rfid",value:rfid});
      }
      
      map["res_func"]="callBackNg";
      map["curl_type"]="getPostData";
     // alert(clsObject);
      CallMapMethod(clsObject,map);
    }
  
  componentDidMount(){
    arrCreateFiles={};
    clsObject=this;
    //AjaxCallMethod(this,"okSuccCNOT");
    //$("#parent_id").val(atob(rf));
    this.getPostData();
    var getDir=[];
    getDir.push({name:"curl_type",value:"getDirectories"});
    getDir.push({name:"res_func",value:"getBackDir"});
    getDir.push({name:"dir_name",value:""});
    getDir.push({name:"type_id",value:"2"});
    getDir["res_func"]="getBackDir";
    getDir["curl_type"]="getDirectories";
      CallMapMethod(clsObject,getDir);
    
    /*Process for New File Creation */
    $(".createFile").keypress(function(event){
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if(keycode=="13"){
        var cFile=[];
      var fileType="0";
      
      if($("#file_type").val()!==null){
        if($("#file_type").val()!=="0"){
          fileType=$("#file_type").val();
        }
      }
      //alert("i am in enter of file creation");
      cFile.push({name:"curl_type",value:"createFile"});
      cFile.push({name:"res_func",value:"getBackFile"});
      cFile.push({name:"file_name",value:$(this).val()});
      cFile.push({name:"file_type",value:fileType});
      cFile.push({name:"type_id",value:"5"});
      cFile["res_func"]="getBackFile";
      cFile["curl_type"]="createFile";
      CallMapMethod(clsObject,cFile);
        //clsObject.getBackFile("micrsco1.docx");
      }
    });
    
    $("#serParentId").change(function(){
      var getCallFunc= $(this).attr("resp_dest");
      var refId=$(this).val();
      //alert("i am in change = "+refId);
      clsObject.getPostData(refId);
      //alert("ai on change "+getCallFunc+","+refId);
      //return obCls[getCallFunc](refId);
     });
   
    $(".createDirect").keypress(function(event){
      var keycode = (event.keyCode ? event.keyCode : event.which);
      if(keycode=="13"){
        //alert("I am in enter");
        var cDir=[];
      var dirParentId=0;
      //alert($("#parent_id").val());
      if($("#folder_parent_id").val()!==null){
        if($("#folder_parent_id").val()!=="0"){
         // alert("ia m in condition");
          dirParentId=$("#folder_parent_id").val();
        }
      }
      cDir.push({name:"curl_type",value:"createDirectory"});
      cDir.push({name:"res_func",value:"getBackDir"});
      cDir.push({name:"dir_name",value:$(this).val()});
      cDir.push({name:"parent_id",value:dirParentId});
      cDir.push({name:"type_id",value:"2"});
      cDir["res_func"]="getBackDir";
      cDir["curl_type"]="createDirectory";
      CallMapMethod(clsObject,cDir);
      }
      
    });
    RespBkMthdMapp(clsObject,"_setSearchKey","keySearch");
    
    //this.showMe("#modal-folder");
  }
  keySearch(data){
    getSearchOutPut("postTimeLn","timeline",data);
  }
  getCreatedFilesArr(){
    var strFile="";
    var keyNms="";
    /*
    for(var i=0;i<arrCreateFiles.length;i++){
      alert(arrCreateFiles[i]["elem"].name);
      strFile+="<span><a class=\"_setbackparamFile\" param1=\""+arrCreateFiles[i].name+"\" href=\"javascript:void(0)\">"+arrCreateFiles[i].name+"</a><button type=\"button\" class=\"btn btn-tool removeBtn\" param1=\""+arrCreateFiles[i].id+"\" data-card-widget=\"remove\"><i class=\"fa fa-times\"></i>"+
      "</button>"+
      "</span>";
    }*/
    for (var key in arrCreateFiles) {
      if(keyNms!==""){
        keyNms+=",";
      }
      keyNms+=key;
        strFile+="<span><a class=\"_setbackparamFile\" param1=\""+arrCreateFiles[key]+"\" href=\"javascript:void(0)\">"+arrCreateFiles[key]+"</a><button type=\"button\" class=\"btn btn-tool _removeBtn\" param1=\""+key+"\" data-card-widget=\"remove\"><i class=\"fa fa-times\"></i>"+
        "</button>"+
        "</span>";
  }
  $("#c_files").val(keyNms);
  return strFile;
}
rmFileProc(rf){
  //alert(rf);
  var cFile=[];
  cFile.push({name:"curl_type",value:"deleteFiles"});
  cFile.push({name:"res_func",value:"rmDelFile"});
  cFile.push({name:"ref",value:rf});
  cFile.push({name:"ref_name",value:arrCreateFiles[rf]});
  cFile.push({name:"type_id",value:"5"});
  cFile["res_func"]="rmDelFile";
  cFile["curl_type"]="deleteFiles";
  CallMapMethod(clsObject,cFile);
}
rmDelFile(data,msg){
  if(data!=="0"){
    delete arrCreateFiles[data];
    var strFile=clsObject.getCreatedFilesArr();
    $("#list_files").html(strFile);
    RespBkMthdMapp(clsObject,"_removeBtn","rmFileProc","2");
    RespBkMthdMapp(clsObject,"_setbackparamFile","vidCBackProc");
  }
}
  getBackFile(data,msg){
    //alert(data);
   if(data!=="0" && data!==""){
     var resFile=JSON.parse(data);
     for(var i=0;i<resFile.length;i++){
      arrCreateFiles[resFile[i]["id"]]=resFile[i]["col1"];
       var strFile=clsObject.getCreatedFilesArr();
       $("#list_files").html(strFile);
       RespBkMthdMapp(clsObject,"_removeBtn","rmFileProc","2");
       RespBkMthdMapp(clsObject,"_setbackparamFile","vidCBackProc");
     
     }
   }
  
  }   
  getBackDir(data,msg){
    // alert(data);
     if(data!=="0"){
       var listDir=JSON.parse(data);
       var strDir="<option value=\"0\">-Select Dir-</option>";
       for(var i=0;i<listDir.length;i++){
         if(listDir[i]["parent_id"]==="0"){
          strDir+="<option class=\"optionGroup\" value=\""+listDir[i]["id"]+"\">"+listDir[i]["col1"]+"</option>";
          const childFltr = d => d.parent_id === listDir[i]["id"];
          var childMenuList = listDir.filter(childFltr);
          for(var j=0;j<childMenuList.length;j++){
            strDir+="<option style=\"padding-left:15px;\" class=\"optionChild\" value=\""+childMenuList[j]["id"]+"\">--"+childMenuList[j]["col1"]+"</option>";
          }
         }
          
       }
       $("#folder_parent_id").html(strDir);
       $("#parent_id").html(strDir);
       $("#serParentId").html(strDir);
       //$("#newDirectory").css("display","none");
       $("#newDirectory").val("");

     }
  }
  callBackNg(data,msg){
    // alert(data);
     
    if(data!=="0"){
     var htmTimeLn= "";
     if(type_rf!=null){
       if(type_rf==="vid"){
         htmTimeLn= VideoGallery(data);
       }else if(type_rf==="img"){
        htmTimeLn=ImageGallery(data);
       }
     }
     if(htmTimeLn===""){
      htmTimeLn= TimeLine(data);
     }
     
     $("#postTimeLn").html(htmTimeLn);
     $(".select_file_ch").change();
    var fList= RespBkMthdMapp(clsObject,"hid_file_ch","vidCBack");
    $('.gallery-item').magnificPopup({
      type: 'image',
      gallery: {
          enabled: true
      }
  });
    RespBkMthdMapp(clsObject,"_setbackparam","vidCBackProc");
    this.vidCBack(fList);
    }else{
      $("#postTimeLn").html("No Data Found..");
    }


  }
  callbkFl(data,msg,obj){
    //alert(data);
    var jsF=JSON.parse(data);
    var param2=obj.getAttribute("param2");
    if(param2){
      //alert("i am in condition");
      $("#"+param2).attr("href",jsF.file_path);
    }
    obj.src=jsF.file_path;
  }
  callbkFlProc(data,msg){
    
  }
  vidCBackProc(ref){
    //alert(ref);
    var map2=[];
    map2.push({name:"curl_type",value:"openFileProcess"});
    map2.push({name:"res_func",value:"callbkFlProc"});
    map2.push({name:"f_name",value:ref});
    map2["res_func"]="callbkFlProc";
    map2["curl_type"]="openFileProcess";
    CallMapMethod(clsObject,map2);
  }
  vidCBack(ref){
   
   for(var i=0;i<ref.length;i++){
    var vidNm= ref[i].getAttribute("param1");
    //  vidNm.toUpperCase().indexOf(".MP4")!=-1 outcondi
   if(vidNm.toUpperCase().indexOf(".JPG")!=-1 || 
   vidNm.toUpperCase().indexOf(".PNG")!=-1 ||
   vidNm.toUpperCase().indexOf(".JPEG")!=-1 ||
   vidNm.toUpperCase().indexOf(".ICO")!=-1 || 
   vidNm.toUpperCase().indexOf(".MP4")!=-1 ||
   vidNm.toUpperCase().indexOf(".GIF")!=-1 || 
   vidNm.toUpperCase().indexOf(".MP3")!=-1
  ){
    var map2=[];
    map2.push({name:"curl_type",value:"getFilePath"});
    map2.push({name:"res_func",value:"callbkFl"});
    map2.push({name:"f_name",value:vidNm});
    map2["res_func"]="callbkFl";
    map2["curl_type"]="getFilePath";
    CallMapMethodRef(clsObject,map2,ref[i]);
   }
   }
  }
    render(){
        return(
<div>
            <Header/>
            {/* <Side_Bar/> */}
            <div className="content-wrapper">

<section className="content-header">
  <div className="container-fluid">
    <div className="row mb-2">
      <div className="col-lg-3 col-md-2 col-sm-3">
      <select id="serParentId" name="serParentId" resp_dest="getTestCB" className="form-control">
        <option value="0">Select Directory</option>
      </select>
      </div>
    </div>
  </div>
</section>


  

  <div className="content">
      <div id="postTimeLn">
      </div>
  </div>
 



</div>
<Footer/>
<Add_New_Post callBackClose={this.CloseExistingPost} stEditData={this.state.stPostEdit}/>
            </div>
        );
    }
}
export default Post_Timeline_New