import { io } from "socket.io-client";
export default class Socket_Lib{
   // static socketIO=null;
    constructor(){
        //this.socketIO=io("http://192.168.0.107:8081/");
        //this.socketIO=io("http://localhost:8081/");
        this.socketIO=io("https://mydreamdata.ashtechnologies.in/");
        // if(typeof this.socketIO==="undefined" || this.socketIO===null){
        //     this.socketIO=io("http://localhost:8081/");
        // }
      
        this.isRun=false;
    }
    sendRequest(eventName,data,callback){
        //alert(eventName);
        this.socketIO.emit(eventName,data);
        if(callback!==null && callback!==""){
            this.socketIO.on("data",function(dataRes){
                //alert("resp===cba12"+dataRes);
                callback(dataRes);
            });
        }
    }
    receiveRequest(eventName,callback){
        this.socketIO.on("eventName",function(data){
            callback(data);
        })
    }
}