import React from 'react';
import ReactDOM from 'react-dom';
import { sortable } from 'react-sortable';
let placeholder;
class SortableList extends React.Component{
    state = {
        items: this.props.items
      };
     
      onSortItems = (items) => {
        this.setState({
          items: items
        });
      }
      constructor(props){
          super(props);
          alert(this.props.items);
         placeholder= document.createElement("li");
          placeholder.className = "placeholder";
      }
    
      dragStart(e) {
        this.dragged = e.currentTarget;
        e.dataTransfer.effectAllowed = 'move';
        // Firefox requires calling dataTransfer.setData
        // for the drag to properly work
        e.dataTransfer.setData("text/html", e.currentTarget);
      }
      dragEnd(e) {
        this.dragged.style.display = "block";
        this.dragged.parentNode.removeChild(placeholder);
        // Update state
        var data = this.state.data;
        var from = Number(this.dragged.dataset.id);
        var to = Number(this.over.dataset.id);
        if(from < to) to--;
        data.splice(to, 0, data.splice(from, 1)[0]);
        this.setState({data: data});
      }
      dragOver(e) {
        e.preventDefault();
        this.dragged.style.display = "none";
        if(e.target.className == "placeholder") return;
        this.over = e.target;
        e.target.parentNode.insertBefore(placeholder, e.target);
      }
     
      render() {
        return (
            <ul onDragOver={this.dragOver}>
              {this.props.items.map(function(item, i) {
                return (
                  <li
                    data-id={i}
                    key={i}
                    draggable="true"
                    onDragEnd={this.dragEnd}
                    onDragStart={this.dragStart}
                  >
                    {item}
                  </li>
                )
              }, this)}
            </ul>
          )
      }
}
export default SortableList;