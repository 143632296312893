import $ from 'jquery';
import React from 'react';
//import OrgChart from '@balkangraph/orgchart.js';
import UserToken from '../function_lib/userToken';
import {GetImgCertUser,CallMapMethod,drawChart,AjaxCallMethod} from '../function_lib/common_lib';
import Header from './header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/Bhandarkarlogo.png';
import defaultPic from '../assets/images/9.png';
import TkMeter from '../assets/images/camera.svg';
import './tree_members.css';

let loginUser="";
let userObject="";
let clsObject;
//var Modal = ReactBootstrap.Modal;
class Tree_Members extends React.Component{
    /*
    constructor(props) {
        super(props);
        this.divRef = React.createRef();
      }
      shouldComponentUpdate() {
        return false;
    }
*/
saveMember(){
    GetImgCertUser(clsObject,"#frmMem","addMember","callBackAddMem");
  }
  callBackAddMem(data,datares,userResp){
    alert(data);
    if(userResp!=="0"){
      $("#getReponse").html("Uploaded Successfully");
      $("#frmCreateComp").find("input[type=text]").val("");
      clsObject.closeMe();
      window.location.reload();
      //clsObject.getTreeData();
    }
    if(data!=="0"){
     
    }else{
      $(".save_resp").html(datares);
    }
  }
closeMe(clName="#modal-member"){
    //$(clName).modal("hide");
    $(clName).hide();
  }
  showMe(parent_id,node_id=0,clName="#modal-member"){
    //alert("i m in click");
    $("#parent_id").val(parent_id);
    //$(clName).modal("show");
    $(clName).show();
    $(clName).addClass("show");
  }

  getTreeData(){
    var map=[];
    map.push({name:"curl_type",value:"getTreeMembers"});
    map.push({name:"res_func",value:"callBackNg"});
  map["res_func"]="callBackNg";
      map["curl_type"]="getTreeMembers";
      CallMapMethod(clsObject,map);
    }
    callBackNg(data,msg){
        // alert(data);
        if(data!=="" && data!=="0"){
            var lsMember=JSON.parse(data);
            var treeMember=[];
            for(var i=0;i<lsMember.length;i++){
              var imgPath="/9.png";
              if(lsMember[i]["col5"]!==""){
                imgPath=lsMember[i]["col5"];
              }
                if(lsMember[i]["app_user_id"]!=="0"){
                    treeMember.push({id:lsMember[i]["id"],
                    pid:lsMember[i]["app_user_id"],
                name:lsMember[i]["col1"],
                title: "HR Manager",
                img: imgPath,
                address:lsMember[i]["col3"]
            });
                }else{
                    treeMember.push({id:lsMember[i]["id"],
                name:lsMember[i]["col1"],
                title: "HR Manager",
                img: imgPath
                });
            }
            drawChart(clsObject,treeMember,"tree");
        }
    }
}
    componentDidMount(){
        clsObject=this;
        this.getTreeData();
        
        
        // this.chart = new OrgChart(this.divRef.current , {
        //                 nodes: 
        //                     [{id: 1, name: "Name1" , title: "Tytle1",img: "https://cdn.balkan.app/shared/5.jpg" },
        //                     {id: 2, pid: 1, name: "Name2" , title: "Tytle2",img: "https://cdn.balkan.app/shared/5.jpg" },
        //                     {id: 3, pid: 1, name: "Name3" , title: "Tytle3",img: "https://cdn.balkan.app/shared/5.jpg" }],

        //                 nodeBinding: {
        //                     field_0: "name",
        //                     field_1: "title"
        //                 }
        //             });
                    
                    AjaxCallMethod(this,"okSuccCNOT");
    }
    render(){
        return(<div>
             <Header/>
            {/* <Side_Bar/> */}
        
        <div className="content-wrapper">
            
             <section className="content">
             <div className="container-fluid">
            
            <div id="tree" ref={this.divRef}></div>
            </div>
            </section>
        </div>
        <div className="modal fade" id="modal-member">
  <div className="modal-dialog">
  <div className="modal-content">
  <form id="frmMem" enctype="multipart/form-data" method="POST" action="#">
  <input type="hidden" name="parent_id" id="parent_id" value="0" />
  <div className="modal-header custom-modal-header">
      <h5 className="modal-title">New File</h5>
      <button type="button" className="close" onClick={()=>this.closeMe("#modal-member")} aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
    <div className="form-group">
      <input className="input-group form-control input-group-sm createFile createFileGp" placeholder="Name" type="text" name="col1" id="col1"  />
    </div>
    <div className="form-group">
      <input className="input-group form-control input-group-sm createFile createFileGp" placeholder="Contact No" type="text" name="col2" id="col2"  />
    </div>
    <div className="form-group">
      <input className="input-group form-control input-group-sm createFile createFileGp" placeholder="Address" type="text" name="col3" id="col3"  />
    </div>
    <div className="form-group">
      <input className="input-group form-control input-group-sm createFile createFileGp" placeholder="Relation" type="text" name="col4" id="col4"  />
    </div>
    <div className="form-group">
      <input className="input-group form-control input-group-sm createFile createFileGp" placeholder="DOB" type="text" name="col5" id="col5"  />
    </div>
    <div className="form-group">
        <label>Profile Pic</label>
    <img style={{width:"12%",marginLeft:"10px"}} setTo="primary_img" className="_brFile" src={TkMeter} alt=""/>
          <div id="resCount" className="resCount"></div>
          <input id="primary_img" name="primary_img" ref_img="_brFile" ref_count="resCount" type="file" className="form-control-file updImg"/>
        
    </div>
    </div>
    <div className="modal-footer justify-content-between">
      <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
      <button type="button" className="btn btn-primary" onClick={()=>this.saveMember()}>Add Now</button>
    </div>
    </form>
  </div>
  </div>
</div>
        </div>);
    }
        
    
}

export default Tree_Members 