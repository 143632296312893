import $  from 'jquery';
import React from 'react';
import  { render } from 'react-dom';
import 'jquery-ui-bundle';
window.jQuery = $;


class Sortpage extends React.Component {
  constructor() {
    super();
    this.state = {
      name: 'React'
    };
    this.node=null;
    
  }
   componentDidMount() {
      $('#sortable').sortable({
        stop:function(event,ui){
          var prev = $(ui.item).prev('div.gridItem').attr('data-id');
        var next = $(ui.item).next('div.gridItem').attr('data-id');
        console.log('prev = '+prev+ ' '+$(ui.item).attr('data-id')+' next = '+next);
        }
      });
    }

  render() {
    return (
      <div className="App CollectionsGrid Profile ownsProfile">

        
    <div className="AppContent column centeredWithin">
            

            <div id="sortable" className="content gridItems collectionItems sort ui-sortable" style={{ height: '570px' }}>

            


                    <div className="gridItem collection ui-state-default" data-id="10674194326556" style={{ visibility: 'visible', display: 'block' }}>
                        <div className="gridItemWrapper">
                            <div className="gridItemInnerWrap">
                                <div className="gridItemDisplay" style={{ backgroundColor: '#eeeeee' }}>
                                    <a href="/demo2015/parks/" className="gridItemLink">
                                        <div className="cover" >
                                            <div className="dim"></div>
                                        </div>

                                        <div className="thumbs">
                                            <div className="thumb" style={{ backgroundColor: '#eeeeee' }}>
                                                <div className="dim"></div>
                                            </div>
                                            <div className="thumb" style={{ backgroundColor: '#eeeeee' }}>
                                                <div className="dim"></div>
                                            </div>
                                        </div>
                                    </a>

                                    <span className="rearrange">Drag to Arrange</span>
                                </div>

                                <div className="detail">
                                    <a href="/demo2015/parks/" className="name">Slow</a>

                                    <span className="icons">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="gridItem collection ui-state-default"  data-id="10674194326558" style={{ visibility: 'visible', display: 'block' }}>
                        <div className="gridItemWrapper">
                            <div className="gridItemInnerWrap">
                                <div className="gridItemDisplay" style={{ backgroundColor: '#eeeeee' }}>
                                    <a href="/demo2015/parks/" className="gridItemLink">
                                        <div className="cover" >
                                            <div className="dim"></div>
                                        </div>

                                        <div className="thumbs">
                                            <div className="thumb" style={{ backgroundColor: '#eeeeee' }}>
                                                <div className="dim"></div>
                                            </div>
                                            <div className="thumb" style={{ backgroundColor: '#eeeeee' }}>
                                                <div className="dim"></div>
                                            </div>
                                        </div>
                                    </a>

                                    <span className="rearrange">Drag to Arrange</span>
                                </div>

                                <div className="detail">
                                    <a href="/demo2015/parks/" className="name">Fast</a>

                                    <span className="icons">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="gridItem collection ui-state-default"  data-id="106741943265589" style={{ visibility: 'visible', display: 'block' }}>
                        <div className="gridItemWrapper">
                            <div className="gridItemInnerWrap">
                                <div className="gridItemDisplay" style={{ backgroundColor: '#eeeeee' }}>
                                    <a href="/demo2015/parks/" className="gridItemLink">
                                        <div className="cover" >
                                            <div className="dim"></div>
                                        </div>

                                        <div className="thumbs">
                                            <div className="thumb" style={{ backgroundColor: '#eeeeee' }}>
                                                <div className="dim"></div>
                                            </div>
                                            <div className="thumb" style={{ backgroundColor: '#eeeeee' }}>
                                                <div className="dim"></div>
                                            </div>
                                        </div>
                                    </a>

                                    <span className="rearrange">Drag to Arrange</span>
                                </div>

                                <div className="detail">
                                    <a href="/demo2015/parks/" className="name">Good</a>

                                    <span className="icons">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
               
                <div className="gridItem collection" data-id="106741943265587858" style={{ visibility: 'visible', display: 'block' }}>
                        <div className="gridItemWrapper">
                            <div className="gridItemInnerWrap">
                                <div className="gridItemDisplay" style={{ backgroundColor: '#eeeeee' }}>
                                    <a href="/demo2015/parks/" className="gridItemLink">
                                        <div className="cover" >
                                            <div className="dim"></div>
                                        </div>

                                        <div className="thumbs">
                                            <div className="thumb" style={{ backgroundColor: '#eeeeee' }}>
                                                <div className="dim"></div>
                                            </div>
                                            <div className="thumb" style={{ backgroundColor: '#eeeeee' }}>
                                                <div className="dim"></div>
                                            </div>
                                        </div>
                                    </a>

                                    <span className="rearrange">Drag to Arrange</span>
                                </div>

                                <div className="detail">
                                    <a href="/demo2015/parks/" className="name">Demo</a>

                                    <span className="icons">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="gridItem collection" data-id="10674194326558242342" style={{ visibility: 'visible', display: 'block' }}>
                        <div className="gridItemWrapper">
                            <div className="gridItemInnerWrap">
                                <div className="gridItemDisplay" style={{ backgroundColor: '#eeeeee' }}>
                                    <a href="/demo2015/parks/" className="gridItemLink">
                                        <div className="cover" >
                                            <div className="dim"></div>
                                        </div>

                                        <div className="thumbs">
                                            <div className="thumb" style={{ backgroundColor: '#eeeeee' }}>
                                                <div className="dim"></div>
                                            </div>
                                            <div className="thumb" style={{ backgroundColor: '#eeeeee' }}>
                                                <div className="dim"></div>
                                            </div>
                                        </div>
                                    </a>

                                    <span className="rearrange">Drag to Arrange</span>
                                </div>

                                <div className="detail">
                                    <a href="/demo2015/parks/" className="name">Test</a>

                                    <span className="icons">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
               
                </div>


            </div>


        </div>
    
    );
  }
}

export default Sortpage;
