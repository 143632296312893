import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod} from '../function_lib/common_lib';
// import listReactFiles from 'list-react-files'
import Header from './header';
import Side_Bar from './side_bar';
import Footer from './footer';
import LogoImg from '../assets/images/my_logo.jpg';
import defaultPic from '../assets/images/9.png';
//Component
import Post_Thumb from '../components/post_thumb';


let loginUser="";
let userObject="";
let clsObject;
class DashboardNew extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.state={ stRecentPost:""};
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        if(localStorage.getItem("userData")!=null){
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          loginUser=userObject[0].person_name;
          //window.location.href="./Dashboard";
        }
      }
      result(vart){
          alert(vart);
      }
    
      componentDidMount(){
        clsObject=this;
         this.getPostData();
         
         
          //listReactFiles("pub_dir/").then(files => console.log(files))
      }
      callBackAdminDash(data,msg){
           //alert(data);
           var getDash=JSON.parse(data);
           $(".tot_comp").html(getDash.total_company);
           $(".tot_emp").html(getDash.total_employee);
           $(".tot_assigned_quiz").html(getDash.assigned_quiz);
           $(".tot_comp_quiz").html(getDash.completed_quiz);
           $(".tot_pending_quiz").html(getDash.pending_quiz);
           
 
           $(".avg_perform").html(getDash.avg_performance);
           $(".quiz_vs_total").html(getDash.completed_quiz+"/"+getDash.assigned_quiz);
           $(".not_attempt").html(getDash.pending_quiz);
           //alert(getDash.total_employee);
      }
      callBackDash(data,msg){
          //alert(data);
          var getDash=JSON.parse(data);
          $(".tot_emp").html(getDash.total_employee);
          $(".tot_assigned_quiz").html(getDash.assigned_quiz);
          $(".tot_comp_quiz").html(getDash.completed_quiz);
          $(".tot_pending_quiz").html(getDash.pending_quiz);

          $(".avg_perform").html(getDash.avg_performance);
          $(".quiz_vs_total").html(getDash.completed_quiz+"/"+getDash.assigned_quiz);
          $(".not_attempt").html(getDash.pending_quiz);
          //alert(getDash.total_employee);
      }
      callBackQuizList(data,msg){
          //alert(data);
          var totalQuiz=0;
          var totalPending=0;
          var totalCompleted=0;
        if(data!=="0" && data!==""){
            var assignList=JSON.parse(data);
            var strList="";
            var quizLink="";
            var quizText="";
            //alert(data);
            for(var i=0;i<assignList.length;i++){
                totalQuiz++;
             quizLink="";
             if(assignList[i]["status"]=="Pending"){
                 totalPending++;
                 quizText="Start Quiz";
                 quizLink="/startQuiz?rf="+assignList[i]["rf_id"]+
                          "&vId="+assignList[i]["video_url"];
             }else{
                 totalCompleted++;
                 quizText="View Preformance";
                quizLink="/quizReport?rf="+assignList[i]["rf_id"]
             }
             strList+="<tr>"+"<td>"+(i+1)+"</td>"+
             "<td>"+assignList[i]["id"]+"</td>"+
             "<td>"+assignList[i]["entry_date"]+"</td>"+
             "<td>"+assignList[i]["col4"]+"</td>"+
             "<td>"+assignList[i]["category_name"]+"</td>"+
             "<td>"+assignList[i]["status"]+"</td>"+
             "<td><a href=\""+quizLink+"\">"+quizText+"</a></td>"+
             "</tr>";
            }
            $("#tdDataDash").html(strList);
            $(".t_totalQuiz").html(totalQuiz);
            $(".t_pendingQuiz").html(totalPending);
            $(".t_completeQuiz").html(totalCompleted);
        }
      }

    render(){
        
        const adminOption=()=>{
            if(userObject[0].type=="3"){
                return(
                
                <div className="row">
                    <div className="col-md-3">
                   
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-warning">
                           <div className="widget-user-image">
                               <img id="pfpicDash" style={{height:"65px",width:"65px"}} className="img-circle elevation-2" src={defaultPic} alt="User Avatar" />
                           </div>
                          
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">Business Crew</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                               <li className="nav-item">
                                   <a href="#" className="nav-link">
                                       Total Quiz Assigend<span className="float-right badge bg-primary t_totalQuiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="#" className="nav-link">
                                       Pending Quiz <span className="float-right badge bg-info t_pendingQuiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="#" className="nav-link">
                                       Completed Quiz <span className="float-right badge bg-success t_completeQuiz"></span>
                                   </a>
                               </li>
                           </ul>
                       </div>
                   </div>
                  
               </div>
                <div className="col-md-9">
                                    <div className="card">
                                        <div className="card-header border-transparent">
                                            <h3 className="card-title">Pending Quiz</h3>
                
                                            <div className="card-tools">
                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                    <i className="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped table-ms">
                                                    <thead>
                                                        <tr style={{backgroundColor:"#FC766AFF"}}>
                                                            <th>S.No.</th>
                                                            <th>Request ID</th>
                                                            <th>Assign Date</th>
                                                            <th>Title</th>
                                                            <th>Category</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id="tdDataDash">
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <a href="/bedBookings?ref_st=all" className="btn btn-sm btn-secondary float-right">View All</a>
                                        </div>
                                    </div>
                                 </div>
                                
                                
                                </div>)
            }
            else if(userObject[0].type=="1"){
                return(<div className="row">
                <div className="col-md-3">
                   
                   <div className="card card-widget widget-user-2">
                       
                       <div className="widget-user-header bg-warning">
                           <div className="widget-user-image">
                               <img id="pfpicDash" style={{height:"65px",width:"65px"}} className="img-circle elevation-2" src={defaultPic} alt="User Avatar" />
                           </div>
                          
                           <h3 className="widget-user-username"></h3>
                           <h5 className="widget-user-desc">Business Crew</h5>
                       </div>
                       <div className="card-footer p-0">
                           <ul className="nav flex-column">
                          
                               <li className="nav-item">
                                   <a href="/useraccounts" className="nav-link">
                                       Total Employee<span className="float-right badge bg-primary tot_emp"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/perfomaceReport" className="nav-link">
                                       Assigned Quiz <span className="float-right badge bg-info tot_assigned_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/perfomaceReport" className="nav-link">
                                       Completed Quiz <span className="float-right badge bg-success tot_comp_quiz"></span>
                                   </a>
                               </li>
                               <li className="nav-item">
                                   <a href="/perfomaceReport" className="nav-link">
                                       Pending Quiz <span className="float-right badge bg-danger tot_pending_quiz"></span>
                                   </a>
                               </li>
                           </ul>
                       </div>
                   </div>
                  
               </div>
               
               <div className="col-md-9">
                   <div className="card">
                       <div className="card-header border-transparent">
                           <h3 className="card-title">Welcome - {userObject[0].person_name}</h3>

                           <div className="card-tools">
                               <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                   <i className="fa fa-minus"></i>
                               </button>
                               <button type="button" className="btn btn-tool" data-card-widget="remove">
                                   <i className="fa fa-times"></i>
                               </button>
                           </div>
                       </div>
                      
                       <div className="card-body p-0">
                           <div className="row">
                               <div className="col-lg-4 col-6">
                                   
                                   <div className="small-box bg-info">
                                       <div className="inner">
                                       <h3><span className="avg_perform"></span><sup style={{fontSize:"20px"}}>%</sup></h3>

                                           <p>Average Performance</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-chart-line"></i>
                                       </div>
                                       <a href="#" className="small-box-footer">
                                           Till Date <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                               
                               <div className="col-lg-4 col-6">
                                  
                                   <div className="small-box bg-success">
                                       <div className="inner">
                                       <h3><div className="quiz_vs_total"></div></h3>

                                           <p>Attempted Quiz/Total</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fal fa-clipboard-user"></i>
                                       </div>
                                       <a href="#" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                             
                               <div className="col-lg-4 col-6">
                                
                                   <div className="small-box bg-warning">
                                       <div className="inner">
                                           <h3><div className="not_attempt"></div></h3>

                                           <p>Not Attempted Quiz</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-user-plus"></i>
                                       </div>
                                       <a href="#" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="card-footer clearfix">

                       </div>
                   </div>
               </div>
               
            </div>);
            }else if(userObject[0].type=="2"){
                return(<div className="row">
               <div className="col-md-12">
                   <div className="card">
                       <div className="card-header border-transparent">
                           <h3 className="card-title">Welcome - {userObject[0].person_name}</h3>

                           <div className="card-tools">
                               <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                   <i className="fa fa-minus"></i>
                               </button>
                               <button type="button" className="btn btn-tool" data-card-widget="remove">
                                   <i className="fa fa-times"></i>
                               </button>
                           </div>
                       </div>
                      
                       <div className="card-body p-0">
                           <div className="row">
                               <div className="col-lg-4 col-6">
                                   
                                   <div className="small-box bg-info">
                                       <div className="inner">
                                       <h3><span className="avg_perform"></span><sup style={{fontSize:"20px"}}>%</sup></h3>

                                           <p>Total Members Entrolled</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-chart-line"></i>
                                       </div>
                                       <a href="#" className="small-box-footer">
                                           Till Date <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                               
                               <div className="col-lg-4 col-6">
                                  
                                   <div className="small-box bg-success">
                                       <div className="inner">
                                       <h3><div className="quiz_vs_total"></div></h3>
                                           <p>Uploaded Documents</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fal fa-clipboard-user"></i>
                                       </div>
                                       <a href="#" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                             
                               <div className="col-lg-4 col-6">
                                
                                   <div className="small-box bg-warning">
                                       <div className="inner">
                                           <h3><div className="not_attempt"></div></h3>

                                           <p>Todays's Reminder</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-user-plus"></i>
                                       </div>
                                       <a href="#" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="card-footer clearfix">

                       </div>
                   </div>
               </div>
               
            </div>)
            }
            
        }
        return(<div>
            <Header/>
            <Side_Bar/>
   <div className="content-wrapper">
        <div className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1 className="m-0 text-danger">Dashboard</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                        <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
            <section className="content">
                <div className="container-fluid">
                <div className="row">
                 <Post_Thumb stRecentPost={this.state.stRecentPost} />
                </div>
                <div className="row">
               <div className="col-md-12">
                   <div className="card">
                       <div className="card-header border-transparent">
                           <h3 className="card-title">Welcome - {userObject[0].person_name}</h3>

                           <div className="card-tools">
                               <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                   <i className="fa fa-minus"></i>
                               </button>
                               <button type="button" className="btn btn-tool" data-card-widget="remove">
                                   <i className="fa fa-times"></i>
                               </button>
                           </div>
                       </div>
                      
                       <div className="card-body p-0">
                           <div className="row">
                               
                               <div className="col-lg-4 col-6">
                                   
                                   <div className="small-box bg-info">
                                       <div className="inner">
                                       <h3><span className="avg_perform"></span><sup style={{fontSize:"20px"}}>20</sup></h3>

                                           <p>Total Members Entrolled</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-chart-line"></i>
                                       </div>
                                       <a href="#" className="small-box-footer">
                                           Till Date <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                               
                               <div className="col-lg-4 col-6">
                                  
                                   <div className="small-box bg-success">
                                       <div className="inner">
                                       <h3><div className="quiz_vs_total">8</div></h3>
                                           <p>Uploaded Documents</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fal fa-clipboard-user"></i>
                                       </div>
                                       <a href="#" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                             
                               <div className="col-lg-4 col-6">
                                
                                   <div className="small-box bg-warning">
                                       <div className="inner">
                                           <h3><div className="not_attempt">9</div></h3>

                                           <p>Todays's Reminder</p>
                                       </div>
                                       <div className="icon">
                                           <i className="fa fa-user-plus"></i>
                                       </div>
                                       <a href="#" className="small-box-footer">
                                           More info <i className="fa fa-arrow-circle-right"></i>
                                       </a>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className="card-footer clearfix">

                       </div>
                   </div>
               </div>
               
            </div>
          
                </div>
            </section>
        </div>
        <aside className="control-sidebar control-sidebar-dark">
          
          <div className="p-3">
            <h5>Title</h5>
            <p>Sidebar content</p>
          </div>
        </aside>
        <Footer/>
      </div>)
    }
}
export default DashboardNew