import $ from 'jquery';
import React,{ useState } from 'react';
import {CallMapMethod,RespBkMthdMapp,getSearchOutPut,taskDate} from '../function_lib/common_lib';
import Header from './header';
import Post_Thumb from '../components/post_thumb';
import Footer from './footer';
import Calendar from 'react-calendar';

import Add_New_Post from '../components/add_new_post';
import 'react-calendar/dist/Calendar.css';

let clsObject;
let crDate;
class Calender_Pg extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
      EditAddPost(data_s){
        //alert(JSON.stringify(data_s));
        clsObject.setState(state=>({stPostEdit:data_s}));
        clsObject.forceUpdateHandler();
      }
      CloseExistingPost(clsModal){
        clsObject.closeMe(clsModal);
        clsObject.setState(state=>({stPostEdit:""}));
        clsObject.forceUpdateHandler();
    }
    closeMe(clName) {
      $(clName).hide();
    }
     onDateChange(values,event){
       var checkDt= taskDate(values);
       var dd=values.getDate();
       var mm=values.getMonth()+1;
       if (dd < 10) { dd = '0' + dd }
   if (mm < 10) { mm = '0' + mm }
       var dtFilter=values.getFullYear()+""+mm+""+dd;
       clsObject.getPostData(dtFilter);
     } 
    constructor(props){
        super(props);
        this.state={ stRecentPost:"",isList:false,stPostEdit:""};
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        crDate=new Date();
    }
    
    getPostData(dtFilter,refId=0,dirParentId=0){
        var map=[];
        map.push({name:"curl_type",value:"getPostData"});
        map.push({name:"res_func",value:"callBackNg"});
        //alert("testing== "+dirParentId);
        map.push({name:"of_date",value:dtFilter});  
        map.push({name:"parent_id",value:dirParentId});
          map["res_func"]="callBackNg";
          map["curl_type"]="getPostData";
          CallMapMethod(clsObject,map);
        }
        callBackNg(data,msg){
            // alert(data);
            if(data.trim()!=="" && data.trim()!=="0"){
                var listItems=JSON.parse(data);
                this.setState(state=>({stRecentPost:JSON.parse(data),isList:true}));
                //clsObject.setState({stRecentPost:listItems,isList:true});
                //alert(JSON.stringify(this.state.stListItems));
                this.forceUpdateHandler();
                RespBkMthdMapp(clsObject,"select_target","onChangeDirSearch","");
             }
        }
    componentDidMount(){
        clsObject=this;
        this.onDateChange(crDate,null);
    }    
    render(){
        return(<div>
            <Header/>
            <div className="content-wrapper">
   <section className="content-header">
  <div className="container-fluid">
    <div className="row mb-2" id="new_catmap_cal">
      <div className="col-lg-12 col-md-12 col-sm-12">
      <Calendar
       onChange={this.onDateChange}
       value={crDate}
     />
      </div>
    
    
    </div>
  </div>
</section>
            <section className="content">
                <div className="container-fluid">
                 { this.state.isList ?<Post_Thumb  callBackMethod={this.EditAddPost} stRecentPost={this.state.stRecentPost}/>:''} 
                </div>
            </section>
        </div>
        <Footer/>
        <Add_New_Post callBackClose={this.CloseExistingPost} stEditData={this.state.stPostEdit}/>
        </div>)
    }
}
export default Calender_Pg;