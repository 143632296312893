import $ from 'jquery';
import React from 'react';
 import LogoImg from '../assets/images/my_logo.jpg';
import UserToken from '../function_lib/userToken';
import {setProfilePic,defaultClick,getAccessRolesNew,CallMapMethod} from '../function_lib/common_lib';
import UserDefault from '../assets/images/9.png';


import './header.css';
let listRoles;
let loginUser;
let userObject;
let companyName="";
class Header extends React.Component{
  logout(){
    localStorage.clear();
    window.location.href="./";
  }
  constructor(props){
    super(props);
    if(localStorage.getItem("userData")!=null){
      UserToken.setUserObject(localStorage.getItem("userData"));
      listRoles= UserToken.getAccessRoles();
      userObject= UserToken.getUserObject();
      //alert(userObject.length);
      loginUser=userObject[0].person_name;
      companyName=userObject[0].company_name;
      //alert(listRoles.length);
    }
  }
  callBackNg(data,msg){
   var check=JSON.parse(data);
   if(check.length==0){
     alert("Invalid User !Kindly contact to Admin")
    localStorage.clear();
    window.location.href="./";
   }
  }
  componentDidMount(){
    // var map=[];
    //     map.push({name:"curl_type",value:"getUserStatus"});
    //     map.push({name:"res_func",value:"callBackNg"});
    //     map.push({name:"gb_token",value:userObject[0].app_user_id});
    //     map.push({name:"rf",value:userObject[0].id});
    //     map["res_func"]="callBackNg";
    //     map["curl_type"]="getUserStatus";
    //     CallMapMethod(this,map);
    if(listRoles.length!=0){
      var listHtm=getAccessRolesNew(listRoles);
      //setProfilePic(document.getElementById("rightProf_Img"),userObject[0].primary_img);
      //alert(listHtm);
      $("#menu_opt").append(listHtm);

    }
    
  }
    render(){
        return(//  <!-- Navbar -->
          <div>
          <nav class="navbar navbar-expand-lg my-bg-color-1 my-shadow">
          <a data-toggle="collapse" data-target="#nav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" class="navbar-brand text-white ml-3" href="#"><span class="navbar-toggler-icon"><i class="fa ffg fa-bars "></i></span> </a>
          <ul class="navbar-nav">
              
          </ul>
          <form className="form-inline ml-3">
<div className="input-group input-group-sm">
  <input className="form-control form-control-navbar _setSearchKey" type="search" placeholder="Search" aria-label="Search" />
  <div className="input-group-append">
	<button className="btn btn-navbar" type="submit">
	  <i className="fa fa-search"></i>
	</button>
  </div>
</div>
</form>
        
          <div class="collapse navbar-collapse" id="nav">
        
             
              <ul id="menu_opt" class="newsettings navbar-nav bg-light-my m-0 ml-lg-auto p-lg-0 m-auto">
              <li class="nav-item logo_new d-lg-none d-md-none d-sm-none">
              <div className="logo_new">
              <span>My Dreams</span>
                <button data-toggle="collapse" data-target="#nav" class="close float-right">&times;</button>
              </div>
              </li>
              
            <li class="nav-item user_title d-lg-none d-md-none d-sm-none">
              <div className="row">
              <div className="col-4"><img src={UserDefault} width="80" class="img-circle img-thumbnail img-fluid m-auto text-cente" /></div>
              <div className="col-8 title_name">Welcome <br></br> {loginUser}</div>
				</div>
				<div class="border-bottom w-100 mt-1 mb-1"></div>
			  
			</li>
      <li class="nav-item has-treeview"><a href="/calender_pg" class="nav-link"><i class="fa fa-calendar fa-1x" aria-hidden="true"></i>Calender</a></li>
      <li class="nav-item has-treeview"><a href="/calender_pg" class="nav-link"><i class="fa fa-clock-o fa-1x" aria-hidden="true"></i>Reminder</a></li>
      <li class="nav-item has-treeview"><a href="/call_list" class="nav-link"><i class="fa fa-phone fa-1x" aria-hidden="true"></i>Call List</a></li>
              </ul>
          </div>
      </nav>


      </div>
          )
    }
}
export default Header